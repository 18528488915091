import { createContext, useCallback, useContext, useEffect, useState } from "react";
import { httpClient } from "../provider/dataProvider";
import { api } from "../api";


const SuperAdminContext = createContext(
    {
        superAdmin: null,
        setSuperAdmin: (val) => { }
    }
)


export function SuperAdminProvider({ children }) {
    const [superAdmin, setSuperAdmin] = useState(false);
    const [called, setCalled] = useState(false);



    useEffect(() => {
        const isSuperAdmin = localStorage.getItem("isSuperAdmin");
        if (isSuperAdmin && isSuperAdmin !== "undefined") {
            if (!called) {
                setCalled(true);
                if (isSuperAdmin === "true") {
                    setSuperAdmin(true);
                }
                // httpClient(`${api}/administrator/${adminId}`)
                //     .then((req) => {
                //         setCalled(true);
                //         if (req?.json?.result?.isSuperAdmin) {
                //             setSuperAdmin(true);
                //         }
                //     })
            }

        }

    })
    return (
        <SuperAdminContext.Provider value={{ superAdmin, setSuperAdmin }}>
            {children}
        </SuperAdminContext.Provider>
    )
}


export function useSuperAdmin() {
    const ctx = useContext(SuperAdminContext);

    if (!ctx) {
        throw new Error("useSuperAdmin must be used within a SuperAdminProvider")
    }

    return ctx
}