import {
    AutocompleteInput, Edit, NumberInput, ReferenceInput, SimpleForm, TextInput, useEditController,
} from "react-admin";
import { EditAndDeleteToolbar, EditToolbar } from "../../components/toolbars/toolbars";
import { useSuperAdmin } from "../../context/superAdmin";
import { CustomEdit } from "../../components/customEdit";

export const SocietyEdit = (props) => {
    const { record } = useEditController();
    const superAdminContext = useSuperAdmin();
    return (
        <CustomEdit title={record?.name} {...props} mutationMode="pessimistic">
            <SimpleForm
                toolbar={superAdminContext.superAdmin ? <EditAndDeleteToolbar /> : <EditToolbar />}
            >
                <TextInput source="name" />

                <ReferenceInput source="cityId" reference="city" queryOptions={{ meta: { nonPaginated: true } }}>
                    <AutocompleteInput optionText="name" defaultValue={null} filterToQuery={(q) => ({ name: q })} />
                </ReferenceInput>

                <NumberInput source="pincode" />
                <ReferenceInput source="localityId" reference="locality" queryOptions={{ meta: { nonPaginated: true } }}>
                    <AutocompleteInput optionText="name" defaultValue={null} filterToQuery={(q) => ({ name: q })} />
                </ReferenceInput>
            </SimpleForm>
        </CustomEdit>
    )
}
