
import {
    BooleanField, Datagrid, List, TextField} from "react-admin";




export const ReportReasonList = (props) => (
    <List {...props}>
        <Datagrid rowClick='show'>
        
            <TextField source="name" />
            <TextField source="description" sx={{overflow: "hidden", textOverflow: "ellipsis"}}  />
            <BooleanField source="isActive" />
            
        </Datagrid>
    </List>
)
