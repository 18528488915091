import {
    FunctionField,
    NumberField, Show, SimpleShowLayout, TextField, useShowController
} from "react-admin";

export const StateShow = (props) => {
    const { record } = useShowController()
    return (
        <Show {...props} title={record?.name}>
            <SimpleShowLayout>
                <TextField source="name" />
                <TextField source="id" label="State Id" />
                <FunctionField label="GST Code" render={record => String(record.gstCode).padStart(2, '0')} />
            </SimpleShowLayout>
        </Show>
    )
}


