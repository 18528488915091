import {
    BooleanInput,
    Create, SelectInput, SimpleForm, TextInput, required,
} from "react-admin";

// import ReactJson from 'react-json-view';

import { FileInputSingle } from "../../components/fileInput"


export const CarouselCreate = (props) => {
    return (

        <Create {...props}>
            <SimpleForm >
                <TextInput source="name" validate={required()} />
                <FileInputSingle source='imageUrl' edit />
                <SelectInput source="type" choices={[
                    {
                        id: "LANDING_PAGE",
                        name: "Landing Page"
                    },
                    {
                        id: "HOME_PAGE",
                        name: "Home Page"
                    },

                ]} validate={required()} />
                <BooleanInput source="isVisible" defaultValue={true} />
            </SimpleForm>
        </Create>
    )
};