
import {
    AutocompleteInput,
    BooleanField, BooleanInput, Datagrid, List, NumberField, NumberInput, ReferenceField, ReferenceInput, SelectInput, TextField, TextInput, useDataProvider, useNotify, useRecordContext, useRefresh
} from "react-admin";
import { FurnishingStateChoices, toWords } from "./propertyHelper";
import { Chip, MenuItem, Select, Input } from "@mui/material";
import { useState } from "react";
import { Modal } from "react-rainbow-components";
import '../OnGoingProjects/og.css'


const propertyFilters = [
    <TextInput source='title' alwaysOn={true} style={{ width: "160px" }} />,
    <TextInput source='description' alwaysOn={true} style={{ width: "160px" }} />,
    <ReferenceInput source="cityId" reference="city" alwaysOn={true} style={{ width: "160px" }} >
        <AutocompleteInput optionText="name" label="City" filterToQuery={(q) => ({ name: q })} />
    </ReferenceInput>,
    <ReferenceInput source="postedByUserId" reference="user" alwaysOn={true} style={{ width: "160px" }} >
        <AutocompleteInput optionText="name" label="User" filterToQuery={(q) => ({ name: q })} />
    </ReferenceInput>,
    <NumberInput source="askPriceStart" alwaysOn={true} style={{ width: "160px" }} />,
    <NumberInput source="askPriceEnd" alwaysOn={true} style={{ width: "160px" }} />,
    <BooleanInput source={"isForRent"} style={{ width: "160px" }} />,
    <SelectInput source="furnishingState" choices={FurnishingStateChoices} style={{ width: "160px" }} />

]


export const PropertyList = (props) => (
    <List {...props} filter={{ isOngoingProject: false }} filters={propertyFilters}>
        <Datagrid rowClick='show' >
            <TextField source="title" />
            <ReferenceField source="postedByUserId" reference="user">
                <TextField source="name" />
            </ReferenceField>
            <NumberField source="askPrice" options={{ style: "currency", currency: "INR", maximumFractionDigits: 2 }} locales={'en-IN'} />
            <PriceWords label="Price in Words"/>
            <ReviewStatus source="reviewStatus" label="Review Status" />
        </Datagrid>
    </List>
)



export function PriceWords(props) {
    const record = useRecordContext()

    return (
        <p>{toWords.convert(Number(record?.askPrice))} </p>
    )
}


export const ReviewStatus = () => {
    const record = useRecordContext()
    const dataProvider = useDataProvider()
    const notify = useNotify()
    const refresh = useRefresh()
    const [open, setOpen] = useState(false);
    const [rejectReason, setRejectReason] = useState();
    const [status, setStatus] = useState()


    //const keyPair = ['Banned', 'New', 'LoggedIn']
    return (
        // <Chip label={keyPair[record.status + 1]}></Chip>

        <>
            <Select
                size='small'
                value={record?.reviewStatus}
                onClick={(e) => {
                    e.stopPropagation();
                    // e.stopImmediatePropagation()
                }}
                onChange={(e) => {
                    e.stopPropagation();
                    e.stopImmediatePropagation();

                    setStatus(e.target?.value)

                    const data = {};
                    data['reviewStatus'] = e.target.value
                    data['id'] = record.id
                    if (e.target.value === "REJECTED") {
                        setOpen(true);
                        return;
                    }
                    data['rejectReason'] = null
                    dataProvider.update('property', {
                        id: record.id,
                        data: data
                    }).then(({ data }) => {
                        if (data?.id) {
                            notify('Successfully updated', { type: 'success' });
                            refresh()
                        } else {
                            notify('Error Updating  Review  Status', { type: 'error' });
                        }

                    }).catch(e => {
                        console.log("error", e)
                        notify('Error Updating  Review  Status', { type: 'error' });
                    }).finally(() => {
                        // setStatus()
                    })
                }}
                defaultValue={record.reviewStatus}
                sx={{ width: "150px" }}
            >
                <MenuItem value={"UNDER_REVIEW"}><Chip label="Under Review" color='warning' size='medium' /></MenuItem>
                <MenuItem value={"PASSED"}><Chip label="Passed" color='info' size='medium' /></MenuItem>
                <MenuItem value={"REJECTED"}><Chip label="Rejected" color='error' size='medium' /></MenuItem>

            </Select>

            <Modal isOpen={open} onRequestClose={() => { }} hideCloseButton  >
                <div className="reject_reason_modal">
                    <div className="reject_reason">Reject Reason</div>
                    <div className="reject_reason_q" >Are you sure to reject the property?</div>
                    <div className="reject_reason_e">Enter  the reason  to reject the poperty.</div>
                    <Input value={rejectReason} onChange={(e) => { setRejectReason(e.target.value) }} multiline />
                    <div className="reject_reason_h">This will be  visible on the app.</div>
                    <div className="ab">
                        <div className="can" onClick={() => { setOpen(false); refresh(); }}>Cancel</div>
                        <div className="sub" onClick={(e) => {
                            console.log('status.....')
                            if (status) {

                                const data = {};
                                data['reviewStatus'] = status
                                data['id'] = record.id
                                data['isDraft'] = true
                                data['rejectReason'] = rejectReason
                                dataProvider.update('property', {
                                    id: record.id,
                                    data: data
                                }).then(({ data }) => {
                                    if (data?.id) {
                                        notify('Successfully updated', { type: 'success' });
                                        setOpen(false)
                                        refresh()
                                    } else {
                                        notify('Error Updating  Review  Status', { type: 'error' });
                                    }
                                }).catch(() => {
                                    console.log("error", e)
                                    notify('Error Updating  Review  Status', { type: 'error' });
                                }).finally(() => {
                                    // setStatus()
                                })
                            }
                        }}>Save</div>
                    </div>
                </div>
            </Modal>
        </>

    )
}