import { fetchUtils } from 'react-admin'

import { api } from '../api'

const httpClient = (url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: 'application/json' })
    options.headers.set('Access-Control-Allow-Origin', '*')
    options.headers.set("type","admin")
  }
  if (localStorage.getItem('accessToken')) {
    options.headers.set('Authorization',`Bearer ${localStorage.getItem('accessToken')}`)
  }
  return fetchUtils.fetchJson(url, options)
}

function isTokenExpired(token) {
  const expiry = (JSON.parse(atob(token.split('.')[1]))).exp;
  return (Math.floor((new Date).getTime() / 1000)) >= expiry;
}

const authProvider = {
  login: async ({ username, password }) => {
    const response = await httpClient(`${api}/administrator/login`, {
      method: 'POST',
      body: JSON.stringify({ username, password }),
    }).then(({ json }) => {
      return json
    })
    if (response.status.error) { 
      return Promise.reject()
    } else {
      console.log('response', response)
      localStorage.setItem('accessToken', response.result?.accessToken)
      localStorage.setItem('refreshToken', response.result?.refreshToken)
      localStorage.setItem('adminId', response.result?.id);
      localStorage.setItem('isSuperAdmin', response.result?.isSuperAdmin)
      return Promise.resolve()
    }
  },
  checkError: (error) => Promise.resolve(),
  checkAuth: async (params) => {
    const at  =  localStorage.getItem('accessToken');
    if(at && at !== 'undefined' ){
      const isExpired = isTokenExpired(at)
      if(!isExpired){
        return Promise.resolve();
      }
      const rt  =  localStorage.getItem('refreshToken')
      if ( rt && rt !== "undefined" ) {
        const response = await httpClient(`${api}/administrator/refresh-token`, {
          method: 'POST',
          body: JSON.stringify({ refreshToken: rt}),
        }).then(({ json }) => {
          return json
        })
        localStorage.setItem('accessToken', response.result)
        return Promise.resolve()
      } else {
        return Promise.reject()
      }
    }else{
      return Promise.reject()
    }
    
  },
  logout: () => {
    localStorage.removeItem('accessToken')
    localStorage.removeItem('refreshToken')
    localStorage.removeItem('isSuperAdmin')
    return Promise.resolve()
  },
  getIdentity: async () => {
    if (localStorage.getItem('adminId')) {
      const response = await httpClient(`${api}/administrator/${localStorage.getItem('adminId')}`, {
        method: 'GET',
      }).then(({ json }) => {
        return json
      })
      return Promise.resolve(response.result)
    }
  },
  getPermissions: () => Promise.resolve(),
}

export default authProvider
