
import {
    Datagrid, FunctionField, List, NumberField, TextField, TextInput
} from "react-admin";


const Filters = [
    <TextInput source='name' alwaysOn={true} />,

]

export const StateList = (props) => (
    <List {...props} filters={Filters}>
        <Datagrid rowClick='show'>
            <TextField source="name" />
            <TextField source="id" label="stateId" />
            <FunctionField label="GST Code" render={record => String(record.gstCode).padStart(2, '0')} />

        </Datagrid>
    </List>
)
