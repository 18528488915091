import {
    BooleanInput, Edit, SimpleForm, TextInput, useEditController,
} from "react-admin";
import { Status } from "./list";
import { EditAndDeleteToolbar, EditToolbar } from "../../components/toolbars/toolbars";
import { useSuperAdmin } from "../../context/superAdmin";
import { CustomEdit } from "../../components/customEdit";

export const UserEdit = (props) => {
    const { record } = useEditController();
    const superAdminContext = useSuperAdmin();
    return (
        <CustomEdit title={record?.name} {...props} mutationMode="pessimistic">
            <SimpleForm
                toolbar={superAdminContext.superAdmin ? <EditAndDeleteToolbar /> : <EditToolbar />}

            >
                <TextInput source="name" />
                <TextInput source="phone" label="Mobile" disabled />
                <Status source="userStatus" label="Staus" />
                <BooleanInput source="isNewUser" />
                <TextInput source="country" />
            </SimpleForm>
        </CustomEdit>
    )
}
