import {
     BooleanInput, Edit, FormDataConsumer, NumberInput,  SelectInput, SimpleForm,  TextInput, required, useEditController
} from "react-admin";
import { JsonInput, validateJson } from "./create";
import { useSuperAdmin } from "../../context/superAdmin";
import { EditAndDeleteToolbar, EditToolbar } from "../../components/toolbars/toolbars";
import { CustomEdit } from "../../components/customEdit";
export const AppSettingsEdit = (props) => {
    const { record } = useEditController();
    const  superAdminContext  = useSuperAdmin()
    return (
        <CustomEdit title={record?.name} {...props}>
            <SimpleForm validate={validateJson} mutationMode="pessimistic"
             toolbar = {  superAdminContext.superAdmin ?  <EditAndDeleteToolbar /> : <EditToolbar/>}
            >
                <TextInput source="key" />
                <SelectInput source="type" choices={[
                    {
                        id: "NUMBER",
                        name: "NUMBER"
                    },
                    {
                        id: "BOOLEAN",
                        name: "BOOLEAN"
                    },
                    {
                        id: "TEXT",
                        name: "TEXT"
                    },
                    {
                        id: "JSON",
                        name: "JSON"
                    }
                ]} validate={required()} />
                <FormDataConsumer>
                    {
                        (({ formData }) => {
                            if (formData?.type) {
                                switch (formData?.type) {
                                    case 'NUMBER': {
                                        return <NumberInput source='value' />;
                                    }
                                    case 'BOOLEAN': {
                                        return <BooleanInput source='value' />;
                                    }
                                    case 'TEXT': {
                                        return <TextInput source='value' />;

                                    }
                                    case 'JSON': {
                                        return <JsonInput source='value' label={"Value"} />;

                                    }
                                    default:
                                        {
                                            <></>
                                        }

                                }
                            }
                        })
                    }
                </FormDataConsumer>
                <TextInput source="description" multiline />
            </SimpleForm>
        </CustomEdit>
    )
}
