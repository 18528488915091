import {
    BooleanInput, DeleteButton, Edit, SaveButton, SelectInput, SimpleForm, TextInput, Toolbar, required, useEditController,
} from "react-admin";
import { FileInputSingle } from "../../components/fileInput"
import { EditAndDeleteToolbar, EditToolbar } from "../../components/toolbars/toolbars";
import { useSuperAdmin } from "../../context/superAdmin";
import { CustomEdit } from "../../components/customEdit";


const CustomToolbar = props => (
    <Toolbar
        {...props}
        sx={{ display: 'flex', justifyContent: 'space-between' }}
    >
        <SaveButton />
        {/* <DeleteButton mutationMode="pessimistic" /> */}
    </Toolbar>
);

export const CarouselsEdit = (props) => {
    const { record } = useEditController();
    const superAdminContext = useSuperAdmin()
    return (
        <CustomEdit title={record?.name} {...props} mutationMode="pessimistic">
            <SimpleForm
                toolbar={superAdminContext.superAdmin ? <EditAndDeleteToolbar /> : <EditToolbar />}

            >
                <TextInput source="name" validate={required()} />
                <FileInputSingle source='imageUrl' edit />
                <SelectInput source="type" choices={[
                    {
                        id: "LANDING_PAGE",
                        name: "Landing Page"
                    },
                    {
                        id: "HOME_PAGE",
                        name: "Home Page"
                    },

                ]} validate={required()} />
                <BooleanInput source="isVisible" defaultValue={true} />
            </SimpleForm>
        </CustomEdit>
    )
}
