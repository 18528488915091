
import { useState } from "react";
import {
    ArrayField,
    ArrayInput, AutocompleteInput, BooleanField, Button, Create, Datagrid, Edit, List, NumberField, NumberInput, ReferenceField, ReferenceInput, Show, SimpleForm, SimpleFormIterator, SimpleShowLayout, TextField, TextInput, useDataProvider, useEditController, useListContext, useNotify, useRefresh, useShowController, useUnselectAll
} from "react-admin";
import { Modal, Button as ButtonRRC } from "react-rainbow-components";

const Filters = [
    <TextInput source='name' alwaysOn={true} />,
    <ReferenceInput source="cityId" reference="city" alwaysOn={true}  >
        <AutocompleteInput optionText="name" label="City" filterToQuery={(q) => ({ name: q })} />
    </ReferenceInput>,
    <TextInput source='pincode' alwaysOn={true} />,
]


export const LocalityList = (props) => (
    <List {...props} filters={Filters}>
        <Datagrid rowClick='show' bulkActionButtons={
            <>
                <MergeRowsButton />
            </>
        }>
            <TextField source="name" />
            <ReferenceField source="cityId" reference="city" >
                <TextField source="name" />
            </ReferenceField>
            <TextField source="pincode" />
        </Datagrid>
    </List>
)

export function MergeRowsButton() {
    const { selectedIds } = useListContext();
    const refresh = useRefresh();
    const notify = useNotify();
    const provider = useDataProvider();
    const unselect = useUnselectAll('locality');

    const [isOpen, setIsOpen] = useState(false);
    const [societies, setSocieties] = useState([]);
    const [society, setSociety] = useState();




    async function getProperties() {
        provider.getList('locality', {
            meta: {
                nonPaginated: true
            },
            filter: {
                id: selectedIds
            }
        }).then(({ data }) => {
            setSocieties(data);
            if (data instanceof Array && data.length > 0) {
                setSociety(data[0].id)
            }
        }).catch(e => {
            console.eror('error', e);
            notify("Error in getting Selected Id Data", { type: 'error' })
        })
    }




    function merge() {
        if (!selectedIds) {
            notify('No Rows selected', { type: "error" });
            return;
        }
        if (!(selectedIds instanceof Array)) {
            notify('No Rows selected', { type: "error" });
            return;

        }
        if (selectedIds.length < 2) {
            notify('Select atleast 2 rows to  merge', { type: "error" });
            return;
        }
        getProperties();
        setIsOpen(true);
    }

    return (
        <>
            <Button label="Merge Columns" onClick={merge} sx={{ background: "white" }} />
            {
                <Modal id="modal-1"
                    isOpen={isOpen}
                    onRequestClose={() => {
                        setIsOpen(false)
                    }}

                > <h2>Merge Society</h2>
                    <h4>Select the Locality to merge into </h4>
                    <select style={{ height: "50px", width: "400px", fontSize: "16px" }} name="" id="" placeholder="" onChange={(e) => {
                        setSociety(e.target.value);
                    }}>
                        {
                            societies.map((it, index) => (
                                <option key = {index} style={{ fontSize: "16px" }} value={it?.id}>{it.name}</option>
                            ))
                        }
                    </select>

                    <h4 style={{ color: "red" }}>Note: Other Localities in the  slection will  be lost & this is irreversible </h4>

                    <ButtonRRC label="Merge" variant="brand" onClick={() => {
                        if (!society) {
                            alert('select target society');
                            return;
                        }
                        provider.mergeLocality({
                            targetId: society,
                            selectedIds: selectedIds,
                        }).then(({ data, message, status }) => {
                            console.log('status', { data, message, status })
                            if (status.error) {
                                notify("something went wrong, retry!", { type: "error" });
                                setIsOpen(false);
                            } else {
                                notify("Merged Successfully", { type: "success" });
                                refresh()
                                setIsOpen(false)
                                unselect()
                               
                            }

                        }).catch(e => {
                            notify("something went wrong, retry!");
                        })
                    }} />
                </Modal>
            }

        </>
    )
}
