import {
    ArrayField,
    ArrayInput, AutocompleteInput, BooleanField, Create, Datagrid, Edit, List, NumberInput, ReferenceInput, SelectInput, Show, SimpleForm, SimpleFormIterator, SimpleShowLayout, TextField, TextInput, required, useEditController, useShowController
} from "react-admin";

export const LocalityCreate = (props) => (
    <Create {...props}>
        <SimpleForm>
            <TextInput source="name" />
            <ReferenceInput source="cityId" reference="city" queryOptions={{ meta: { nonPaginated: true } }}>
                <AutocompleteInput optionText="name" defaultValue={null}  validate={required()} filterToQuery={(q) => ({ name: q })} />
            </ReferenceInput>
            <TextInput source="pincode" />
        </SimpleForm>
    </Create>
);

