import {
    BooleanField, Show, SimpleShowLayout, TextField, useShowController
} from "react-admin";
import FileInput from "../../components/fileInput"

export const CarouselShow = (props) => {
    const { record } = useShowController()
    return (
        <Show {...props} title={record?.name}>
            <SimpleShowLayout>
                <TextField source="name" />
                <TextField source="type" />
                <BooleanField source='isVisible' />
                <FileInput source='imageUrl' />

            </SimpleShowLayout>
        </Show>
    )
}


