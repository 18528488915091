import {
  ArrayField,
  ArrayInput, BooleanField, BooleanInput, Create, Datagrid, Edit, List, PasswordInput, Show, SimpleForm, SimpleFormIterator, SimpleShowLayout, TextField, TextInput, required, useEditController, useShowController
} from "react-admin";

export const AdministratorCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="username" validate={required()} />
      <PasswordInput source="password" validate={required()} />
      <TextInput source="phone" />
      <TextInput source="email" />
      <BooleanInput source="isSuperAdmin" />
    </SimpleForm>
  </Create>
);

