import {
    ArrayInput, AutocompleteInput, BooleanInput, Create, DateInput, FormDataConsumer, NumberInput, ReferenceInput, SelectInput, SimpleFormIterator, TabbedForm, TextInput, required, useCreateContext,
} from "react-admin";
import { AreaUnitChoices, ConfigurationChoices, ExtraRoomChoices, FurnishingStateChoices, PowerBackupChoices, ReviewStausChoices, SellStatusTypeChoices } from "./propertyHelper";

import FileInput, { FileInputSingle } from "../../components/fileInput";
import { useFormContext } from 'react-hook-form'
import { CustomAdd, CustomRemove, MapPicker } from "../OnGoingProjects/edit";
import { PriceInWordsForMutation } from "./edit";


export const PropertyCreate = (props) => {
    const record = useCreateContext();
    console.log("record", record);
    return (
        <Create {...props}>
            <TabbedForm values={{ isOngoingProject: false }}>
                <TabbedForm.Tab label="Basic Details">
                    <TextInput source="title" />
                    <TextInput source='description' multiline />
                    <ReferenceInput source="postedByUserId" reference="user">
                        <AutocompleteInput optionText="phone" defaultValue={null} filterToQuery={(q) => ({ phone: q })} />
                    </ReferenceInput>
                    <SelectInput source="reviewStatus" choices={ReviewStausChoices} validate={required()} />
                    <BooleanInput source="isApproved" />
                    <BooleanInput source="isForRent" />
                    <NumberInput source='askPrice' locales={'en-IN'}/>
                    <PriceInWordsForMutation label="Price in Words" />
                    <NumberInput source='securityDeposite' />
                    <BooleanInput source='isMaintenenceIncluded' />
                    <NumberInput source='maintenance' />
                    <DateInput source='postedOn' />

                    <SelectInput source="propertyConfiguration" choices={ConfigurationChoices} validate={required()} />
                    <AutocompleteInput source="extraRooms" choices={ExtraRoomChoices} multiple />
                    <SelectInput source="sellStatus" choices={SellStatusTypeChoices} validate={required()} />
                    <SelectInput source="propertyConfiguration" choices={PowerBackupChoices} />
                    <SelectInput source="furnishingState" choices={FurnishingStateChoices} validate={required()} />
                    <ReferenceInput source="cityId" reference="city">
                        <AutocompleteInput optionText="name" defaultValue={null} filterToQuery={(q) => ({ name: q })} />
                    </ReferenceInput>
                    <ReferenceInput source="localityId" reference="locality">
                        <AutocompleteInput optionText="name" defaultValue={null} filterToQuery={(q) => ({ name: q })} />
                    </ReferenceInput>
                    <ReferenceInput source="societyId" reference="society">
                        <AutocompleteInput optionText="name" defaultValue={null} filterToQuery={(q) => ({ name: q })} />
                    </ReferenceInput>
                </TabbedForm.Tab>

                <TabbedForm.Tab label="Property Details">
                    <NumberInput source='numberOfBedrooms' />
                    <NumberInput source='numberOfBathrooms' />
                    <NumberInput source='numberOfBalconies' />
                    <div style={{ display: 'flex', gap: "10px" }}>
                        <NumberInput source='superArea' />
                        <SelectInput source="superAreaUnit" choices={AreaUnitChoices} validate={required()} />
                    </div>
                    <div style={{ display: 'flex', gap: "10px" }}>
                        <NumberInput source='plotArea' />
                        <SelectInput source="plotAreaUnit" choices={AreaUnitChoices} validate={required()} />
                    </div>

                    <div style={{ display: 'flex', gap: "10px" }}>
                        <NumberInput source='carpetArea' />
                        <SelectInput source="carpetAreaUnit" choices={AreaUnitChoices} validate={required()} />
                    </div>


                    <FormDataConsumer>
                        {({ formData, ...rest }) => (
                            !formData.isForRent && <BooleanInput source='isUnderLoan' />
                        )}
                    </FormDataConsumer>



                    <NumberInput source='twoWheeler' />
                    <NumberInput source='fourWheeler' />
                    <BooleanInput source='isShared' />
                    <BooleanInput source='isCovered' />
                </TabbedForm.Tab>

                <TabbedForm.Tab label="NearBy">
                    <ArrayInput source="nearBy" >
                        <SimpleFormIterator addButton={<CustomAdd text="Add Near By" />} removeButton={<CustomRemove text="Remove" />} disableClear inline disableReordering>
                            <TextInput source='text' />
                            <TextInput source='distance' />

                        </SimpleFormIterator>
                    </ArrayInput>
                </TabbedForm.Tab>
                <TabbedForm.Tab label={"Location"}>
                    <TextInput source='address' />
                    <MapPicker />
                    {/* <NumberInput source="location.lat" label='Latitude' />
                    <NumberInput source="location.long" label='Longitude' /> */}
                    {/* <TextInput source="location.googleMapsUrl" label='Google Maps Url' fullWidth /> */}
                    <FileInputSingle source="locationMap" edit title="Loaction Map" />

                </TabbedForm.Tab>

                <TabbedForm.Tab label={"Media"}>
                    <FileInput source='media' edit add />
                </TabbedForm.Tab>
            </TabbedForm>

        </Create>
    );
}






export function TenentsChoices() {
    const { register } = useFormContext()

    return (
        <div style={{ display: "flex", flexDirection: "column", gap: "5px", marginBottom: "15px" }}>
            {/* <BooleanInput source='forFamily' />
            <BooleanInput source='forBachelorBoys' />
            <BooleanInput source='forBachelorGirls' /> */}
            <h4>Tenent Choices</h4>
            <div style={{ display: 'inline', gap: "10px" }}>
                <input type="checkbox" {...register('forFamily')} />
                <label htmlFor=""> For Family</label>
            </div>
            <div style={{ display: 'inline', gap: "10px" }}>
                <input type="checkbox" {...register('forBachelorBoys')} />
                <label htmlFor=""> For Bachelor Boys </label>
            </div>
            <div style={{ display: 'inline', gap: "10px" }}>
                <input type="checkbox" {...register('forBachelorGirls')} />
                <label htmlFor=""> For Bachelor Girls</label>
            </div>

        </div>
    )
}

