import { AddOutlined, Delete, Edit } from '@mui/icons-material'
import { Button, IconButton } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useRecordContext } from 'react-admin'
import { useFormContext } from 'react-hook-form'
import { api } from '../api'
import './fileInputStyle.css'
import * as lodash from 'lodash'

const FileInput = ({ upload = true, title = "Upload Image", edit = false, source = 'images', resource, value, route, field, resourceField, add, onFormUpload, deleteButton = true, ...props }) => {
  const b = useFormContext()
  const c = useRecordContext(props)
  const [files, setFiles] = useState(() => {
    if (!value) {
      value = c?.[source]
    }
    if (value && value instanceof Array) {
      return value
    } else if (value && typeof value === 'string') {
      return [{ link: value, name: '', reference: '' }]
    }
    return [{ link: '', name: '', reference: '' }]
  })



  const addFile = () => {
    setFiles([...files, { link: '', name: '', reference: '' }])
  }

  useEffect(() => {
    if (onFormUpload) {
      onFormUpload(files)
      return
    }
    if (b && b.setValue) {
      if (add) {
        b.setValue(source, files, { shouldDirty: true })
      } else {
        b.setValue(source, {
          link: files[0].link,
          name: files[0].name,
          referenceId: files[0].referenceId
        }, { shouldDirty: true })
      }
    }
  }, [files])

  const handleFileUpload = async (e, i) => {
    const formData = new FormData()
    formData.append('file', e.target.files[0])
    await fetch(
      `${api}/file/upload`,
      {
        method: 'POST',
        headers: new Headers({
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          'Access-Control-Allow-Origin': '*',

        }),
        body: formData,
      }
    ).then((res) => res.json())
      .then(body => {
        if (!body.status.error) {
          const copy = [...files]
          copy[i] = { link: body.result?.link, name: body.result.name, reference: copy?.[i]?.reference ?? '' }
          setFiles(copy)
        }
      })
  }

  return (
    <div>
      <h3>{title}</h3>
      {(files ?? [{ link: '', name: '', reference: '' }]).map((file, index) => {
        return (
          <React.Fragment key={index}>
            {edit && <IconButton onClick={() => {
              const copy = [...files]
              copy[index] = { link: '', name: '', reference: '' }
              setFiles(copy)
            }}> <Edit /></IconButton>}
            {edit && deleteButton && <IconButton onClick={() => {
              const copy = [...files]
              copy.splice(index, 1)
              setFiles(copy)
            }}> <Delete style={{ color: 'red' }} /></IconButton>}
            {
              !file.link && upload ? (
                <label className='fileInput' value="">
                  <input type='file' onChange={(e) => handleFileUpload(e, index)} accept='image/*, application/pdf' />
                  Upload
                </label>
              ) : /(.pdf)$/.test(file.name) ? (
                <div className='file'>
                  <iframe title="Images" src={file.link} width="600px" height="600px" ></iframe>
                </div>
              ) : file.link
                ? (
                  <div className='file'>
                    <img src={file.link} width="600px" height="500px" style={{ objectFit: 'fill' }} alt='images' />
                  </div>
                ) : null}
          </React.Fragment>
        )
      })
      }
      <br />
      {add && <Button onClick={addFile} startIcon={<AddOutlined />}> Add</Button>}
    </div >
  )
}

export const FileInputSingle = ({
  title = "Upload File", edit = false,
  upload = true, source = 'image', fileHeight, fileWidth,
  resource, route, field, resourceField, add, ...props
}) => {
  const b = useFormContext()
  const c = useRecordContext(props)


  // const [files, setFiles] = useState([c?.[source]])
  const [files, setFiles] = useState([lodash.get(c, source)])
  // console.log("files....................",lodash.get(c,source))
  const handleFileUpload = async (e, i) => {
    const formData = new FormData()
    formData.append('file', e.target.files[0])
    await fetch(
      `${api}/file/upload`,
      {
        method: 'POST',
        headers: new Headers({
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          'Access-Control-Allow-Origin': '*',

        }),
        body: formData,
      }
    ).then((res) => res.json())
      .then(body => {
        if (!body.status.error) {
          const copy = [...files]
          copy[i] = { link: body.result?.link, name: body.result.name, reference: copy?.[i]?.reference ?? '' }
          setFiles(copy)
        }
      })
  }
  const addFile = () => {
    setFiles([...files, { link: '', name: '', reference: '' }])
  }

  useEffect(() => {
    if (b && b.setValue) {
      b.setValue(source, files[0], { shouldDirty: true })
    }
  }, [files])

  return (
    <div>
      {
        title && title !== '' && <h3>{title}</h3>
      }
      {(files ?? [{ link: '', name: '', reference: '' }]).map((file, index) => {
        // console.log('file', file);
        return (
          <React.Fragment key={index}>
            {edit && <IconButton onClick={() => {
              const copy = [...files]
              copy[index] = { ...copy[index], link: '', name: '' }
              setFiles(copy)
            }}> <Edit /></IconButton>}
            {
              !file?.link && upload ? (
                <label className='fileInput' value="">
                  <input type='file' onChange={(e) => handleFileUpload(e, index)} accept='image/*, application/pdf' />
                  Upload
                </label>
              ) : /(.pdf)$/.test(file.name) ? (
                <div className='file'>
                  <iframe src={file.link} width={fileWidth || 600} height={fileHeight || 600} title="file" ></iframe>
                </div>
              ) : file.link ?
                (
                  <div className='file'>
                    <img src={file.link} width={fileWidth || 600} height={fileHeight || 600} style={{ objectFit: 'scale-down' }} alt='images'></img>
                  </div>
                ) : null}
          </React.Fragment>
        )
      })
      }
      <br />
      {add && <Button onClick={addFile} startIcon={<AddOutlined />}> Add</Button>}
    </div >
  )
}

export const FileCustomInput = (
  { route, edit, title, upload = true, file, setFile }
) => {
  const [state, setState] = useState()
  const b = useFormContext()
  console.log("context", b)
  console.log('file', file)
  const uploadFileHandler = async (e) => {
    const formData = new FormData()
    formData.append('file', e.target.files[0])

    await fetch(
      `${api}/${route}`,
      {
        method: 'POST',
        headers: new Headers({
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          'Access-Control-Allow-Origin': '*'
        }),
        body: formData,
      }
    ).then((res) => res.json())
      .then(body => {
        if (!body.status.error) {
          setFile({ link: body.result?.link, name: body.result.name, reference: body.result?.reference })
        }
      })
  }
  return (
    <div>
      <h3>{title}</h3>
      {edit && file.link && <IconButton onClick={() => {
        setFile({})
      }}> <Edit /></IconButton>}
      {
        !file.link && upload ? (
          <label className='fileInput' style={{ backgroundColor: '#fd5f14' }} value="">
            {state && <input type='file' onChange={uploadFileHandler} accept='image/*, application/pdf' />}
            Upload
          </label>
        ) : /(.pdf)$/.test(file.name) ? (
          <div className='file'>
            <iframe src={file.link} width="600px" height="600px" title='file' />
          </div>
        ) : file.link ?
          (
            <div className='file'>
              <img src={file.link} width="600px" height="500px" style={{ objectFit: 'fill' }} alt='file'></img>
            </div>
          ) : null
      }

    </div>
  )
}

export default FileInput
