import { Chip, MenuItem, Select } from "@mui/material";
import {
  BooleanField, Datagrid, DateField, DateInput, List, SelectInput, TextField, TextInput, useDataProvider, useNotify, useRecordContext, useRefresh
} from "react-admin";


export const Status = () => {
  const record = useRecordContext()
  const dataProvider = useDataProvider()
  const notify = useNotify()
  const refresh = useRefresh()
  //const keyPair = ['Banned', 'New', 'LoggedIn']
  return (
    // <Chip label={keyPair[record.status + 1]}></Chip>
    <Select
      size='small'
      value={record?.status}
      onClick={(e) => {
        e.stopPropagation();
        e.stopImmediatePropagation()
      }}
      onChange={(e) => {
        e.stopPropagation();
        //e.stopImmediatePropagation()
        dataProvider.update('user', {
          id: record.id,
          data: {
            id: record.id, userStatus: e.target.value
          }
        }).then(v => { notify('Successfully updated', { type: 'success' }); refresh() })
      }}
      defaultValue={record.userStatus}
      sx={{ width: "150px" }}
    >
      <MenuItem value={"ACTIVE"}><Chip label="Active" color='success' size='small' /></MenuItem>
      <MenuItem value={"GREYLISTED"}><Chip label="Grey Listed" color='default' size='small' /></MenuItem>
      <MenuItem value={"BLACKLISTED"}><Chip label="Black Listed" color='default' sx={{ background: "black", color: "white" }} size='small' /></MenuItem>
    </Select>
  )
}


const Type = () => {
  const record = useRecordContext()
  const dataProvider = useDataProvider()
  const notify = useNotify()
  const refresh = useRefresh()
  //const keyPair = ['Banned', 'New', 'LoggedIn']
  return (
    // <Chip label={keyPair[record.status + 1]}></Chip>
    <Select
      size='small'
      value={record?.status}
      onClick={(e) => {
        e.stopPropagation();
        // e.stopImmediatePropagation()
      }}
      onChange={(e) => {
        e.stopPropagation();
        e.stopImmediatePropagation()
        dataProvider.update('user', {
          id: record.id,
          data: {
            id: record.id, type: e.target.value
          }
        }).then(v => { notify('Successfully updated', { type: 'success' }); refresh() })
      }}
      defaultValue={record.type}
      sx={{ width: "150px" }}
    >
      <MenuItem value={"BROKER"}><Chip label="Agent" color='warning' size='medium' /></MenuItem>
      <MenuItem value={"CUSTOMER"}><Chip label="Individual" color='info' size='medium' /></MenuItem>
    </Select>
  )
}


const Filters = [
  <TextInput source='name' alwaysOn={true} />,
  <TextInput source='phone' label="Mobile" alwaysOn={true} />,
  <SelectInput source='userStatus' choices={[{
    id: "ACTIVE",
    name: "Active"
  },
  {
    id: "GREYLISTED",
    name: "Grey Listed",
  },
  {
    id: "BLACKLISTED",
    name: "Black Listed",
  }

  ]} alwaysOn={true} emptyText={"All"} />,
  <SelectInput source='type' choices={[
    // {
    //   id: "HOME_OWNER",
    //   name: "Home Owner"
    // },
    {
      id: "BROKER",
      name: "Agent",
    },
    {
      id: "CUSTOMER",
      name: "Individual",
    }

  ]} alwaysOn={true} emptyText={"All"} />,
  <DateInput source="fromCreatedDate" alwaysOn={true} />,
  <DateInput source="toCreatedDate" alwaysOn={true} />,
]



export const UserList = (props) => (
  <List {...props} actions={false} filters={Filters}>
    <Datagrid rowClick='show' >
      <TextField source="name" />
      <TextField source="phone" label="Mobile" />
      {/* <TextField source="country  " /> */}
      <Status source="userStatus" label="Staus" />
      <Type source="type" label="Type" />
      <BooleanField source="isNewUser" />
      <DateField source="createdAt"  locales={'en-IN'} showTime/>
      <TextField source="country" />
      <TextField source="fistLoginCountry" />
      <TextField source="firstLoginIp" />
    </Datagrid>
  </List>
)
