import {
    AutocompleteInput, BooleanInput, Edit, ReferenceInput, SimpleForm, TextInput, useEditController,
} from "react-admin";
import { EditAndDeleteToolbar, EditToolbar } from "../../components/toolbars/toolbars";
import { useSuperAdmin } from "../../context/superAdmin";
import { CustomEdit } from "../../components/customEdit";

export const CityEdit = (props) => {
    const { record } = useEditController();
    const superAdminContext = useSuperAdmin()
    return (
        <CustomEdit title={record?.name} {...props} mutationMode="pessimistic">
            <SimpleForm
                toolbar={superAdminContext.superAdmin ? <EditAndDeleteToolbar /> : <EditToolbar />}

            >
                <TextInput source="name" />
                <ReferenceInput source="stateId" reference="state">
                    <AutocompleteInput optionText="name" defaultValue={null} />
                </ReferenceInput>
                <BooleanInput source="isPopularCity" />
            </SimpleForm>
        </CustomEdit>
    )
}
