
import {
    Datagrid, List, TextField, TextInput
} from "react-admin";

const Filters = [
    <TextInput source='key' alwaysOn={true} />,

]


export const AppSettingsList = (props) => (
    <List {...props} filters={Filters}>
        <Datagrid rowClick='show'>
            <TextField source="key" />
            <TextField source="type" />
            <TextField aria-multiline source="value" />
            <TextField aria-multiline source="description" />
        </Datagrid>
    </List>
)
