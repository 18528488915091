import { DeleteButton, Edit, ShowButton, TopToolbar, UpdateButton } from "react-admin";




const EditActions = () => (
    <TopToolbar>
        <ShowButton />
        <DeleteButton />
    </TopToolbar>
);




export function CustomEdit(props) {
    return (
        <Edit {...props} mutationMode="pessimistic" actions={<EditActions />}>
            {props.children}
        </Edit>
    )
}