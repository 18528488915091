import DefaultIcon from "@mui/icons-material/ViewList";
import { AppBar, ToggleThemeButton } from "react-admin";
import {
  Admin,
  CustomRoutes,
  Layout,
  Menu,
  MenuItemLink,
  Resource,
  useCreatePath,
  useGetResourceLabel,
  useResourceDefinitions,
} from "react-admin";
import { Route } from "react-router-dom";
import "./App.css";
import authProvider from "./provider/authProvider";
import dataProvider from "./provider/dataProvider";

import { Home } from "./views/Home";

import {
  FaUsersCog,
  FaUserAlt,
  FaHome,
  FaLandmark,
  FaCity,
  FaImage,
} from "react-icons/fa";
import { AiFillDashboard } from "react-icons/ai";
import {
  MdReport,
  MdLocalActivity,
  MdOutlineSocialDistance,
  MdAppSettingsAlt,
} from "react-icons/md";
import { SiReason } from "react-icons/si";
import { GiFamilyHouse } from "react-icons/gi";

import { UserEdit } from "./views/User/edit";
import { UserShow } from "./views/User/show";
import { UserList } from "./views/User/list";
import { AdministratorList } from "./views/Administrator/list";
import { AdministratorShow } from "./views/Administrator/show";
import { AdministratorEdit } from "./views/Administrator/edit";
import { AdministratorCreate } from "./views/Administrator/create";
import { CityList } from "./views/City/list";
import { CityShow } from "./views/City/show";
import { CityEdit } from "./views/City/edit";
import { CityCreate } from "./views/City/create";
import { StateList } from "./views/State/list";
import { StateEdit } from "./views/State/edit";
import { StateCreate } from "./views/State/create";
import { StateShow } from "./views/State/show";
import { SocietyList } from "./views/Society/list";
import { SocietyShow } from "./views/Society/show";
import { SocietyEdit } from "./views/Society/edit";
import { SocietyCreate } from "./views/Society/create";
import { LocalityList } from "./views/Locality/list";
import { LoclityShow } from "./views/Locality/show";
import { LocalityCreate } from "./views/Locality/create";
import { LocalityEdit } from "./views/Locality/edit";
import { PropertyList } from "./views/Property/list";
import { OngoingProjectList } from "./views/OnGoingProjects/list";
import { PropertyShow } from "./views/Property/show";
import { PropertyCreate } from "./views/Property/create";
import { PropertyEdit } from "./views/Property/edit";
import { OngoingProjectShow } from "./views/OnGoingProjects/show";
import { OngoingProjectEdit } from "./views/OnGoingProjects/edit";
import { OngoingProjectCreate } from "./views/OnGoingProjects/create";
import { ReportList } from "./views/Report/list";
import { ReportShow } from "./views/Report/show";
import { ReportEdit } from "./views/Report/edit";
import { ReportReasonList } from "./views/ReportReason/list";
import { ReportReasonShow } from "./views/ReportReason/show";
import { ReportReasonEdit } from "./views/ReportReason/edit";
import { ReportReasonCreate } from "./views/ReportReason/create";
import { AppSettingsList } from "./views/AppSettings/list";
import { AppSettingsShow } from "./views/AppSettings/show";
import { AppSettingsEdit } from "./views/AppSettings/edit";
import { AppSettingsCreate } from "./views/AppSettings/create";
import { CarouselList } from "./views/Carousel/list";
import { CarouselShow } from "./views/Carousel/show";
import { CarouselsEdit } from "./views/Carousel/edit";
import { CarouselCreate } from "./views/Carousel/create";
import { SuperAdminProvider, useSuperAdmin } from "./context/superAdmin";

const MyMenu = () => {
  const resources = useResourceDefinitions();
  const createPath = useCreatePath();
  const getResourceLabel = useGetResourceLabel();
  // console.log(resources);
  // const defaultObject = Object.keys(resources).map((name) => (
  //   <Menu.Item key={name} name={name} />
  // ));

  return (
    <Menu>
      <Menu.Item
        to="/dashboard"
        primaryText="Dashboard"
        leftIcon={<AiFillDashboard style={{ color: "#3284ff" }} />}
      />
      {Object.keys(resources)
        .filter((name) => resources[name].hasList)
        .map((name) => (
          <MenuItemLink
            key={name}
            to={createPath({
              resource: name,
              type: "list",
            })}
            state={{ _scrollToTop: true }}
            primaryText={getResourceLabel(name, 2)}
            leftIcon={
              resources[name].icon ? resources[name].icon : <DefaultIcon />
            }
          />
        ))}
    </Menu>
  );
};

export const MyAppBar = () => <AppBar toolbar={<ToggleThemeButton />} />;

const MyLayout = (props) => (
  <Layout {...props} menu={MyMenu} appBar={MyAppBar} />
);

const App = () => {
  return (
    <SuperAdminProvider>
      <AppBody />
    </SuperAdminProvider>
  );
};

export function AppBody() {
  const superAdminContext = useSuperAdmin();
  return (
    <Admin
      title="No Broerage Admin"
      dataProvider={dataProvider}
      authProvider={authProvider}
      layout={MyLayout}
      dashboard={Home}
      darkTheme={{ palette: { mode: "dark" } }}
    >
      <Resource
        name="user"
        list={UserList}
        show={UserShow}
        edit={UserEdit}
        // create={UserCreate}
        icon={<FaUserAlt style={{ color: "#3284ff" }} />}
      />
      <Resource
        name="property"
        list={PropertyList}
        icon={<FaHome style={{ color: "#3284ff" }} />}
        show={PropertyShow}
        create={PropertyCreate}
        edit={PropertyEdit}
      />

      <Resource
        name="ongoingProject"
        options={{ label: "Onging Projects" }}
        list={OngoingProjectList}
        show={OngoingProjectShow}
        edit={OngoingProjectEdit}
        create={OngoingProjectCreate}
        icon={<GiFamilyHouse style={{ color: "#3284ff" }} />}
      />

      <Resource
        name="report"
        list={ReportList}
        show={ReportShow}
        edit={ReportEdit}
        icon={<MdReport style={{ color: "#3284ff" }} />}
      />

      <Resource
        name="report-reason"
        options={{ label: "Report Reasons" }}
        list={ReportReasonList}
        show={ReportReasonShow}
        edit={ReportReasonEdit}
        create={ReportReasonCreate}
        icon={<SiReason style={{ color: "#3284ff" }} />}
      />

      <Resource
        name="state"
        list={StateList}
        show={StateShow}
        edit={StateEdit}
        create={StateCreate}
        icon={<FaLandmark style={{ color: "#3284ff" }} />}
      />

      <Resource
        name="city"
        list={CityList}
        show={CityShow}
        edit={CityEdit}
        create={CityCreate}
        icon={<FaCity style={{ color: "#3284ff" }} />}
      />

      <Resource
        name="locality"
        list={LocalityList}
        show={LoclityShow}
        edit={LocalityEdit}
        create={LocalityCreate}
        icon={<MdLocalActivity style={{ color: "#3284ff" }} />}
      />

      <Resource
        name="society"
        list={SocietyList}
        show={SocietyShow}
        edit={SocietyEdit}
        create={SocietyCreate}
        icon={<MdOutlineSocialDistance style={{ color: "#3284ff" }} />}
      />

      <Resource
        name="carousel"
        list={CarouselList}
        show={CarouselShow}
        edit={CarouselsEdit}
        create={CarouselCreate}
        icon={<FaImage style={{ color: "#3284ff" }} />}
      />

      <Resource
        name="appSettings"
        list={AppSettingsList}
        show={AppSettingsShow}
        edit={AppSettingsEdit}
        create={AppSettingsCreate}
        icon={<MdAppSettingsAlt style={{ color: "#3284ff" }} />}
      />
      {superAdminContext.superAdmin && (
        <Resource
          name="administrator"
          list={AdministratorList}
          show={AdministratorShow}
          edit={AdministratorEdit}
          create={AdministratorCreate}
          icon={<FaUsersCog style={{ color: "#3284ff" }} />}
        />
      )}

      <CustomRoutes>
        <Route path="/dashboard" element={<Home />} />
      </CustomRoutes>
    </Admin>
  );
}

export default App;
