import {
    ArrayField,
    BooleanField, Datagrid, DateField, EmailField, NumberField, ReferenceField, Show, TabbedShowLayout, TextField, UrlField, useRecordContext, useShowController
} from "react-admin";
import FileInput, { FileInputSingle } from "../../components/fileInput";
import { PriceWords } from "../Property/list";

export const OngoingProjectShow = (props) => {
    const { record } = useShowController()
    return (
        <Show {...props} title={record?.name}>
            <TabbedShowLayout >
                <TabbedShowLayout.Tab label="Basic Info">
                    <TextField source="title" />
                    <TextField source="description" label="description" />
                    {/* <BooleanField source='isForRent' /> */}
                    <NumberField source='askPrice' options={{ style: "currency", currency: "INR", maximumFractionDigits: 2 }} locales={'en-IN'} />
                    <PriceWords label="Price in Words"/>
                    {/* <NumberField source='securityDeposite' /> */}
                    {/* <BooleanField source='isMaintenenceIncluded' />
                    <NumberField source="mintenence" /> */}
                    <DateField source="postedOn" />
                    <BooleanField source="forFamily" />
                    <BooleanField source="forBachelorBoys" />
                    <BooleanField source="forbachelorGirls" />
                    <TextField source="powerBackup" />
                    <TextField source="sellStatus" />
                    <TextField source="furnishingState" />
                    <ReferenceField source="cityId" reference="city" >
                        <TextField source="name" />
                    </ReferenceField>
                    <ReferenceField source="localityId" reference="locality" >
                        <TextField source="name" />
                    </ReferenceField>
                    <ReferenceField source="societyId" reference="society" >
                        <TextField source="name" />
                    </ReferenceField>
                </TabbedShowLayout.Tab>
                <TabbedShowLayout.Tab label='Parking Details'>
                    {/* <NumberField source='numberOfBedrooms' />
                    <NumberField source='numberOfBathrooms' />
                    <NumberField source='numberOfBalconies' />
                    <NumberField source='masterArea' />
                    <NumberField source='plotArea' />
                    <NumberField source='carpetArea' />
                    <NumberField source='askPrice' />
                    <BooleanField source='isUnderLoan' /> */}
                    <NumberField source='twoWheeler' />
                    <NumberField source='fourWheeler' />
                    {/* <BooleanField source='isShared' />
                    <BooleanField source='isCovered' /> */}
                </TabbedShowLayout.Tab>
                {
                    record?.nearBy &&
                    <TabbedShowLayout.Tab label={'Near By'}>
                        <ArrayField source="nearBy">
                            <Datagrid bulkActionButtons={false}>

                                <TextField source="text" />
                                <TextField source="distance" />
                            </Datagrid>
                        </ArrayField>
                    </TabbedShowLayout.Tab>
                }
                {
                    record?.sizeAndFloorPlan && <TabbedShowLayout.Tab label="Floor Plans">
                        <ArrayField source="sizeAndFloorPlan">
                            <Datagrid bulkActionButtons={false}>
                                <FileInputSingle source='planImage' fileHeight={200} fileWidth={200} />
                                <TextField source="planConfig" />
                                <NumberField source='planArea' />
                                <TextField source="planAreaUnit" />
                            </Datagrid>
                            <FileInputSingle source='sitePlan' title="Site Plan" fileHeight={200} fileWidth={200} />
                        </ArrayField>

                    </TabbedShowLayout.Tab>
                }
                {
                    record?.projectLayingPayments &&
                    <TabbedShowLayout.Tab label="Payment Plans">
                        <ArrayField source='projectLayingPayments'>
                            <Datagrid bulkActionButtons={false}>
                                <TextField source={'paymentHeader'} />
                                {/* <NumberField source='securityAmount' /> */}
                                <ArrayField source='payments'>
                                    <Datagrid bulkActionButtons={false}>
                                        <TextField source="paymentName" />
                                        <NumberField source="isPercentage" />
                                        <NumberField source="value" />

                                    </Datagrid>
                                </ArrayField>
                            </Datagrid>
                        </ArrayField>
                    </TabbedShowLayout.Tab>
                }

                {/* {
                    record?.projectSpecifications &&
                    <TabbedShowLayout.Tab label="Specifications">
                        <ArrayField source="projectSpecifications">
                            <Datagrid bulkActionButtons={false}>
                                <TextField source={'type'} />
                                <ArrayField source='sizes'>
                                    <Datagrid bulkActionButtons={false}>
                                        <NumberField source="size" />
                                        <TextField source="sizeUnit" />
                                    </Datagrid>
                                </ArrayField>
                            </Datagrid>
                        </ArrayField>
                    </TabbedShowLayout.Tab>
                } */}
                {
                    record?.faq && <TabbedShowLayout.Tab label="FAQ">
                        <ArrayField source='faq'>
                            <Datagrid bulkActionButtons={false}>
                                <TextField source='question' />
                                <TextField source='answer' />
                            </Datagrid>
                        </ArrayField>
                    </TabbedShowLayout.Tab>
                }


                {record?.amenities && <TabbedShowLayout.Tab label="Amenities">
                    <ArrayField source='amenities.club'>
                        <Datagrid bulkActionButtons={false}>
                            <BooleanField source='isAvailabele' />
                            <TextField source='name' />
                            <FileInputSingle source='image' fileHeight={100} fileWidth={100} title="" />
                        </Datagrid>
                    </ArrayField>
                    <ArrayField source='amenities.sportsAndFitness'>
                        <Datagrid bulkActionButtons={false}>
                            <BooleanField source='isAvailabele' />
                            <TextField source='name' />
                            <FileInputSingle source='image' fileHeight={100} fileWidth={100} title="" />
                        </Datagrid>
                    </ArrayField><ArrayField source='amenities.forChildren'>
                        <Datagrid bulkActionButtons={false}>
                            <BooleanField source='isAvailabele' />
                            <TextField source='name' />
                            <FileInputSingle source='image' fileHeight={100} fileWidth={100} title="" />
                        </Datagrid>
                    </ArrayField><ArrayField source='amenities.safetyAndSecurity'>
                        <Datagrid bulkActionButtons={false}>
                            <BooleanField source='isAvailabele' />
                            <TextField source='name' />
                            <FileInputSingle source='image' fileHeight={100} fileWidth={100} title="" />
                        </Datagrid>
                    </ArrayField><ArrayField source='amenities.generalAmenities'>
                        <Datagrid bulkActionButtons={false}>
                            <BooleanField source='isAvailabele' />
                            <TextField source='name' />
                            <FileInputSingle source='image' fileHeight={100} fileWidth={100} title="" />
                        </Datagrid>
                    </ArrayField><ArrayField source='amenitiesotherAmenities'>
                        <Datagrid bulkActionButtons={false}>
                            <BooleanField source='isAvailabele' />
                            <TextField source='name' />
                            <FileInputSingle source='image' fileHeight={100} fileWidth={100} title="" />
                        </Datagrid>
                    </ArrayField>
                </TabbedShowLayout.Tab>
                }

                {record?.contactInfoForProjects && <TabbedShowLayout.Tab label="Contact Info">
                    <TextField source='contactInfoForProjects.name' label="Name" />
                    <TextField source='contactInfoForProjects.phone' label="Phone" />
                    <EmailField source='contactInfoForProjects.email' label="Email" />
                </TabbedShowLayout.Tab>
                }
                <TabbedShowLayout.Tab label='Location'>
                    <TextField source='address' aria-multiline />
                    {/* <UrlField source="location.googleMapsUrl" /> */}
                    <TextField source='location.lat' aria-multiline label="Latitude" />
                    <TextField source='location.long' aria-multiline label="Longitude" />



                    {/* <NumberField source='location.lat' label='Latitude' />
                    <NumberField source='location.long' label='Longitude' /> */}
                </TabbedShowLayout.Tab>
                <TabbedShowLayout.Tab label={"Media"}>
                    <FileInput source='media' />
                </TabbedShowLayout.Tab>
            </TabbedShowLayout>
        </Show>
    )
}


export function ShowOnMap() {
    const record = useRecordContext();
    return (
        <>{
            record?.location?.lat && record?.location.long && <a target="_blank" href={`https://www.google.com/maps?${record?.location.lat},${record?.location.long}`} style={{ display: 'flex', width: "max-content", padding: '10px', border: "1px  solid black", borderRadius: "10px" }}>
                See On Google Maps
            </a>
        }
        </>

    )
}


