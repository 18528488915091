import {
  ArrayField,
  ArrayInput, BooleanField, Create, Datagrid, Edit, List, Show, SimpleForm, SimpleFormIterator, SimpleShowLayout, TextField, TextInput, useEditController, useShowController
} from "react-admin";


const Filters = [
  <TextInput source='username' alwaysOn={true} />,
]


export const AdministratorList = (props) => (
  < >
    {
      localStorage.getItem('isSuperAdmin') == 'true' ? <List {...props} filters={Filters}>
        <Datagrid rowClick='show' bulkActionButtons={false}>
          <TextField source="username" /><TextField source="name" />
          <TextField source="phone" />
          <BooleanField source="isSuperAdmin" />
        </Datagrid>
      </List>: <>You are not allowed to view this page</>
    }

  </>
)
