import {
     BooleanField,  ReferenceField, Show,  SimpleShowLayout, TextField, useShowController
} from "react-admin";

export const CityShow = (props) => {
    const { record } = useShowController()
    return (
        <Show {...props} title={record?.name}>
            <SimpleShowLayout>
                <TextField source="name" />
                <ReferenceField source="stateId" reference="state">
                    <TextField source="name"/>
                </ReferenceField>
                <BooleanField source="isPopularCity" />
            </SimpleShowLayout>
        </Show>
    )
}


