
import {
    BooleanField, Datagrid, List, NumberField, ReferenceField, TextField, TextInput, ReferenceInput, AutocompleteInput, BooleanInput, SelectInput, NumberInput
} from "react-admin";
import { FurnishingStateChoices } from "../Property/propertyHelper";
import { PriceWords } from "../Property/list";


const propertyFilters = [
    <TextInput source='title' alwaysOn={true} style={{ width: "160px" }} />,
    <TextInput source='description' alwaysOn={true} style={{ width: "160px" }} />,
    <ReferenceInput source="cityId" reference="city" alwaysOn={true} style={{ width: "160px" }}>
        <AutocompleteInput optionText="name" label="City" filterToQuery={(q) => ({ name: q })} style={{ width: "160px" }} />
    </ReferenceInput>,
    <ReferenceInput source="postedByUserId" reference="user" alwaysOn={true} style={{ width: "160px" }}>
        <AutocompleteInput optionText="name" label="User" filterToQuery={(q) => ({ name: q })} />
    </ReferenceInput>,
    <NumberInput source="askPriceStart" alwaysOn={true} style={{ width: "160px" }} />,
    <NumberInput source="askPriceEnd" alwaysOn={true} style={{ width: "160px" }} />


]

export const OngoingProjectList = (props) => (
    <List {...props} filter={{ isOngoingProject: true }} filters={propertyFilters}>
        <Datagrid rowClick='show'>
            <TextField source="title" />
            <NumberField source="askPrice" options={{ style: "currency", currency: "INR", maximumFractionDigits: 2 }} locales={'en-IN'} />
            <PriceWords label="Price in Words"/>
            <BooleanField source="isForRent" />
            <ReferenceField source="cityId" reference="city">
                <TextField source="name" />
            </ReferenceField>
        </Datagrid>
    </List>
)
