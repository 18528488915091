
import {
    AutocompleteInput, BooleanField, Datagrid, List, ReferenceInput, TextField, TextInput
} from "react-admin";

const Filters = [
    <TextInput source='name' alwaysOn={true} />,
    <ReferenceInput source="stateId" reference="state" alwaysOn={true}  >
        <AutocompleteInput optionText="name" label="State" filterToQuery={(q) => ({ name: q })} />
    </ReferenceInput>,
    // <BooleanInput source='isPopularCity' alwaysOn={true} />,
]


export const CityList = (props) => (
    <List {...props} filters={Filters}>
        <Datagrid rowClick='show'>
            <TextField source="name" />
            <TextField source="stateId" />
            <BooleanField source="isPopularCity" />
        </Datagrid>
    </List>
)
