import {
    ArrayField, BooleanField, Datagrid, DateField, NumberField, ReferenceField, Show, TabbedShowLayout, TextField, useShowController
} from "react-admin";
import FileInput from "../../components/fileInput";
import { PriceWords, ReviewStatus } from "./list";
import { ShowOnMap } from "../OnGoingProjects/show";


export const PropertyShow = (props) => {
    const { record } = useShowController()
    return (
        <Show {...props} title={record?.name}>
            <TabbedShowLayout >
                <TabbedShowLayout.Tab label="Basic Info">
                    <ReviewStatus source="reviewStatus" label="Review Status" />
                    <TextField source="title" />
                    <TextField source="description" label="description" />
                    <BooleanField source='isForRent' />
                    <NumberField source='askPrice' options={{ style: "currency", currency: "INR", maximumFractionDigits: 2 }} locales={'en-IN'} />
                    <PriceWords label="Price in Words" />
                    <NumberField source='securityDeposite' />
                    <BooleanField source='isMaintenenceIncluded' />
                    <NumberField source="mintenence" />
                    <TextField source="address" />
                    <DateField source="postedOn" />
                    <BooleanField source="forFamily" />
                    <BooleanField source="forBachelorBoys" />
                    <BooleanField source="forbachelorGirls" />
                    <TextField source="propertyConfiguration" />
                    <TextField source="sellStatus" />
                    <TextField source="furnishingState" />
                    <ReferenceField source="cityId" reference="city" >
                        <TextField source="name" />
                    </ReferenceField>
                    <ReferenceField source="localityId" reference="locality" >
                        <TextField source="name" />
                    </ReferenceField>
                    <ReferenceField source="societyId" reference="society" >
                        <TextField source="name" />
                    </ReferenceField>
                </TabbedShowLayout.Tab>
                <TabbedShowLayout.Tab label='Details'>
                    <NumberField source='numberOfBedrooms' />
                    <NumberField source='numberOfBathrooms' />
                    <NumberField source='numberOfBalconies' />
                    <NumberField source='superArea' />
                    <TextField source='superAreaUnit' />
                    <NumberField source='plotArea' />
                    <TextField source='plotAreaUnit' />
                    <NumberField source='carpetArea' />
                    <TextField source='carpetAreaUnit' />
                    <NumberField source='askPrice' />
                    <BooleanField source='isUnderLoan' />
                    <NumberField source='twoWheeler' />
                    <NumberField source='fourWheeler' />
                    <BooleanField source='isShared' />
                    <BooleanField source='isCovered' />
                </TabbedShowLayout.Tab>
                {
                    record?.nearBy && <TabbedShowLayout.Tab label={'Near By'}>
                        <ArrayField source="nearBy">
                            <Datagrid bulkActionButtons={false}>
                                <TextField source="id" />
                                <TextField source="text" />
                            </Datagrid>
                        </ArrayField>
                    </TabbedShowLayout.Tab>
                }
                <TabbedShowLayout.Tab label='Location'>
                    <TextField source='address' aria-multiline />
                    <NumberField source='location.lat' label='Latitude' />
                    <NumberField source='location.long' label='Longitude' />
                    <ShowOnMap />
                </TabbedShowLayout.Tab>

                <TabbedShowLayout.Tab label={"Media"}>
                    <FileInput source='media' />
                </TabbedShowLayout.Tab>
            </TabbedShowLayout>
        </Show>
    )
}


