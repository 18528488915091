import {

    ArrayInput, AutocompleteInput, BooleanInput, Create, DateInput, NumberInput, ReferenceInput, SelectInput, SimpleFormIterator, TabbedForm, TextInput, required
} from "react-admin";
import { AreaUnitChoices, ConfigurationChoices, ExtraRoomChoices, FurnishingStateChoices, PowerBackupChoices, PropertyTypeChoices, defaultAmenities, toWords } from "../Property/propertyHelper";
import FileInput, { FileInputSingle } from "../../components/fileInput";
import { CustomAdd, CustomRemove, MapPicker } from "./edit";
import { useEffect, useState } from "react";
import { CheckboxGroup } from "react-rainbow-components";
import './og.css'

import { useFormContext, useWatch } from 'react-hook-form'
import { PriceInWordsForMutation } from "../Property/edit";


export const OngoingProjectCreate = (props) => (
    <Create {...props}>
        <TabbedForm values={{ isOngoingProject: false }}>
            <TabbedForm.Tab label="Basic Details">
                <TextInput source="title" />
                <TextInput source='description' multiline fullWidth />
                {/* <SelectInput source="reviewStatus" choices={ReviewStausChoices} validate={required()} /> */}
                {/* <BooleanInput source="isForRent" /> */}
                <NumberInput source='askPrice' />
                <PriceInWordsForMutation label="Price in Words" />
                {/* <NumberInput source='securityDeposite' />
                <BooleanInput source='isMaintenenceIncluded' />
                <NumberInput source='maintenance' /> */}
                <DateInput source='postedOn' />
                {/* <BooleanInput source='forFamily' />
                <BooleanInput source='forBachelorBoys' />
                <BooleanInput source='forBachelorGirls' /> */}
                {/* <SelectInput source="ownershipType" choices={OwnerShipChoices} validate={required()} /> */}
                {/* <SelectInput source="propertyConfiguration" choices={ConfigurationChoices} validate={required()} /> */}
                {/* <AutocompleteInput source="extraRooms" choices={ExtraRoomChoices} multiple /> */}
                <SelectInput source="powerBackup" choices={PowerBackupChoices} />


                {/* <SelectInput source="furnishingState" choices={FurnishingStateChoices} validate={required()} /> */}
                <SelectInput source="propertyType" choices={PropertyTypeChoices} validate={required()} />
                <ReferenceInput source="cityId" reference="city">
                    <AutocompleteInput optionText="name" defaultValue={null} filterToQuery={(q) => ({ name: q })} />
                </ReferenceInput>
                <ReferenceInput source="localityId" reference="locality">
                    <AutocompleteInput optionText="name" defaultValue={null} filterToQuery={(q) => ({ name: q })} />
                </ReferenceInput>
                <ReferenceInput source="societyId" reference="society">
                    <AutocompleteInput optionText="name" defaultValue={null} filterToQuery={(q) => ({ name: q })} />
                </ReferenceInput>
                <FileInputSingle source='brochure' title="Brochure" edit />
            </TabbedForm.Tab>
            <TabbedForm.Tab label="Parking Details">
                {/* <NumberInput source='numberOfBedrooms' />
                <NumberInput source='numberOfBathrooms' />
                <NumberInput source='numberOfBalconies' />
                <NumberInput source='masterArea' />
                <NumberInput source='plotArea' />
                <NumberInput source='carpetArea' />
                <NumberInput source='askPrice' />
                <BooleanInput source='isUnderLoan' /> */}
                <NumberInput source='twoWheeler' />
                <NumberInput source='fourWheeler' />
                {/* <BooleanInput source='isShared' />
                <BooleanInput source='isCovered' /> */}
            </TabbedForm.Tab>
            <TabbedForm.Tab label="Near By">
                <ArrayInput source="nearBy">
                    <SimpleFormIterator addButton={<CustomAdd text="Add Near By" />} removeButton={<CustomRemove text="Remove" />} disableClear inline disableReordering>
                        <TextInput source='text' />
                        <TextInput source='distance' />

                    </SimpleFormIterator>
                </ArrayInput>
            </TabbedForm.Tab>
            <TabbedForm.Tab label="Floor Plans">
                <FileInputSingle source='sitePlan' edit title="Upload Site Plan" />
                <div style={{ display: 'flex', width: '80vw', height: '2px', background: "red" }}></div>
                <ArrayInput source="sizeAndFloorPlan" label="Floor Plans" >
                    <SimpleFormIterator addButton={<CustomAdd text="Add Floor Plan" />} removeButton={<CustomRemove text="Remove" />} disableClear inline disableReordering>
                        <SelectInput source="planConfig" choices={ConfigurationChoices} validate={required()} />
                        <NumberInput source='planArea' />
                        <SelectInput source="planAreaUnit" choices={AreaUnitChoices} />
                        <FileInputSingle source='planImage' edit fileHeight={200} fileWidth={200} title="Image" />
                    </SimpleFormIterator>
                </ArrayInput>

            </TabbedForm.Tab>



            <TabbedForm.Tab label="payment Plans">

                <ArrayInput source="projectLayingPayments"  >
                    <SimpleFormIterator inline sx={{ borderColor: "blue" }} addButton={<CustomAdd text="Add Payment plan" />} removeButton={<CustomRemove text="Remove" />} disableClear disableReordering>
                        <TextInput source="paymentHeader" />
                        {/* <NumberInput source='securityAmount' /> */}
                        <ArrayInput source="payments">
                            <SimpleFormIterator addButton={<CustomAdd text="Add Plan" />} removeButton={<CustomRemove text="Remove" />} disableClear inline disableReordering >
                                <TextInput source="paymentName" />
                                <BooleanInput source="isPercentage" />
                                <NumberInput source="value"   />
                            </SimpleFormIterator>
                        </ArrayInput>
                        <div style={{ display: 'flex', width: '80vw', height: '2px', background: "red" }}></div>
                    </SimpleFormIterator>
                </ArrayInput>
            </TabbedForm.Tab>
            {/* <TabbedForm.Tab label="Specifications">
                <ArrayInput source="projectSpecifications"  >
                    <SimpleFormIterator addButton={<CustomAdd text="Add Specification" />} removeButton={<CustomRemove text="Remove" />} disableClear inline disableReordering >
                        <TextInput source="type" />
                        <ArrayInput source="sizes">
                            <SimpleFormIterator addButton={<CustomAdd text="Add Size" />} removeButton={<CustomRemove text="Remove" />} disableClear inline disableReordering>
                                <TextInput source="id" />
                                <NumberInput source="size" />
                            </SimpleFormIterator>
                        </ArrayInput>
                        <div style={{ display: 'flex', width: '80vw', height: '2px', background: "red" }}></div>
                    </SimpleFormIterator>
                </ArrayInput>

            </TabbedForm.Tab> */}

            <TabbedForm.Tab label="FAQ">
                <ArrayInput source="faq"  >
                    <SimpleFormIterator fullWidth addButton={<CustomAdd text="Add FAQ" />} removeButton={<CustomRemove text="Remove" />} disableClear inline disableReordering>
                        <TextInput source="question" />
                        <TextInput source="answer" multiline sx={{ width: '750px' }} />
                    </SimpleFormIterator>
                </ArrayInput>
            </TabbedForm.Tab>


            <TabbedForm.Tab label="Amenities">
                <Amenities />
            </TabbedForm.Tab>
            <TabbedForm.Tab label="Contact Info">
                <TextInput source="contactInfoForProjects.name" label='Name' />
                <TextInput source="contactInfoForProjects.phone" label="Phone" />
                <TextInput source="contactInfoForProjects.email" label='Email' />
            </TabbedForm.Tab>
            <TabbedForm.Tab label={"Location"}>
                <TextInput source='address' multiline />
                <MapPicker />
                {/* <NumberInput source="location.lat" label='Latitude' />
                <NumberInput source="location.long" label='Longitude' /> */}
                {/* <TextInput source="location.googleMapsUrl" label='Google Maps Url' fullWidth /> */}
                <FileInputSingle source="locationMap" edit title="Loaction Map" />
            </TabbedForm.Tab>
            <TabbedForm.Tab label={"Media"}>
                <FileInput source='media' edit add title="Media" />
            </TabbedForm.Tab>
        </TabbedForm>
    </Create>
);







export function Amenities(props) {
    const [club, setClub] = useState([]);
    const [sports, setSports] = useState([]);
    const [children, setChildren] = useState([]);
    const [safety, setSafety] = useState([]);
    const [general, setGeneral] = useState([]);
    const [other, setOther] = useState([]);
    // const [amenities, setAmenities] = useState(defaultAmenities);
    const formCtx = useFormContext()
    function clubChange(values) {
        setClub(values)
    };
    function sportsChange(values) {
        setSports(values)
    };
    function childrenChange(values) {
        setChildren(values)
    };
    function safetyChange(values) {
        setSafety(values)
    };
    function generalChange(values) {
        setGeneral(values)
    };

    function otherChange(values) {
        setOther(values)
    };

    useEffect(() => {
        defaultAmenities.club.forEach((it, index) => {
            if (club.includes(it?.id)) {
                defaultAmenities.club[index].isAvailabele = true
            } else {
                defaultAmenities.club[index].isAvailabele = false
            }
        })
        defaultAmenities.sportsAndFitness.forEach((it, index) => {
            if (sports.includes(it?.id)) {
                defaultAmenities.sportsAndFitness[index].isAvailabele = true
            } else {
                defaultAmenities.sportsAndFitness[index].isAvailabele = false
            }
        })
        defaultAmenities.forChildren.forEach((it, index) => {
            if (children.includes(it?.id)) {
                defaultAmenities.forChildren[index].isAvailabele = true
            } else {
                defaultAmenities.forChildren[index].isAvailabele = false
            }
        })
        defaultAmenities.safetyAndSecurity.forEach((it, index) => {
            if (safety.includes(it?.id)) {
                defaultAmenities.safetyAndSecurity[index].isAvailabele = true
            } else {
                defaultAmenities.safetyAndSecurity[index].isAvailabele = false
            }
        })
        defaultAmenities.generalAmenities.forEach((it, index) => {
            if (general.includes(it?.id)) {
                defaultAmenities.generalAmenities[index].isAvailabele = true
            } else {
                defaultAmenities.generalAmenities[index].isAvailabele = false
            }
        })
        defaultAmenities.otherAmenities.forEach((it, index) => {
            if (other.includes(it?.id)) {
                defaultAmenities.otherAmenities[index].isAvailabele = true
            } else {
                defaultAmenities.otherAmenities[index].isAvailabele = false
            }
        });
        // setAmenities(defaultAmenities);
        formCtx.setValue('amenities', defaultAmenities);
    }, [club, sports, safety, children, general, other]);

    // console.log('formCtx', formCtx);
    // console.log("amenities", { club, sports, safety, children, other, general });

    return (
        <div className="rainbow-p-vertical_large rainbow-p-left_xx-large amenities" >
            <CheckboxGroup
                id="checkbox-group-1"
                label="Club Amenities"
                options={defaultAmenities?.club.map(it => ({ value: it?.id, label: it?.name, disabled: false }))}
                value={club}
                onChange={clubChange}
                className="amenity"
                {...props}
            />
            <CheckboxGroup
                id="checkbox-group-1"
                label="Sports Amenities"
                options={defaultAmenities?.sportsAndFitness.map(it => ({ value: it?.id, label: it?.name, disabled: false }))}
                value={sports}
                onChange={sportsChange}
                className="amenity"
                {...props}
            />
            <CheckboxGroup
                id="checkbox-group-1"
                label="For Children"
                options={defaultAmenities?.forChildren.map(it => ({ value: it?.id, label: it?.name, disabled: false }))}
                value={children}
                onChange={childrenChange}
                className="amenity"
                {...props}
            />
            <CheckboxGroup
                id="checkbox-group-1"
                label="Safety &  Security"
                options={defaultAmenities?.safetyAndSecurity.map(it => ({ value: it?.id, label: it?.name, disabled: false }))}
                value={safety}
                onChange={safetyChange}
                className="amenity"
                {...props}
            />
            <CheckboxGroup
                id="checkbox-group-1"
                label="General Amenities"
                options={defaultAmenities?.generalAmenities.map(it => ({ value: it?.id, label: it?.name, disabled: false }))}
                value={general}
                onChange={generalChange}
                className="amenity"
                {...props}
            />
            <CheckboxGroup
                id="checkbox-group-1"
                label="Other Amenities"
                options={defaultAmenities?.otherAmenities.map(it => ({ value: it?.id, label: it?.name, disabled: false }))}
                value={other}
                onChange={otherChange}
                className="amenity"
                {...props}
            />
        </div>
    );

}


