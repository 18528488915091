import { BooleanInput, Edit, Labeled, SaveButton, SimpleForm, TextField, TextInput, CheckboxGroupInput, useRecordContext, Toolbar, PasswordInput, ArrayInput, SimpleFormIterator, DeleteButton } from "react-admin"
import * as React from 'react';
import { useState, useEffect } from 'react'
import Box from '@mui/material/Box';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Checkbox from '@mui/material/Checkbox';
import { useFormContext } from 'react-hook-form'
import { CustomEdit } from "../../components/customEdit";

export const AdministratorEdit = (props) => {
  return (
    <CustomEdit {...props} mutationMode="pessimistic">
      <SimpleForm
        toolbar={
          <Toolbar>
            <SaveButton alwaysEnable={true} />
            <DeleteButton />
          </Toolbar>
        }
      >
        <Labeled label="useranme">
          <TextField source="username" />
        </Labeled>
        <TextInput source="email" />
        <TextInput source="phone" />
        <PasswordInput source="newPassword" />
        <Labeled label="Min password length is 8 *">
          <TextField source="ant" />
        </Labeled>
        <BooleanInput source="isSuperAdmin" />
        <Permissions source="permissions" edit />
      </SimpleForm>
    </CustomEdit>
  )
}

export function Permissions({ name }) {
  const record = useRecordContext()
  const permissions = record.permissions
  const [all, setAll] = useState(permissions);
  const form = useFormContext()
  useEffect(() => {
    console.log("permissions", form);
    if (form.setValue) {
      console.log("it -----");
      form.setValue('permissions', all, { shouldDirty: true })
    }
  }, [all])
  return (
    <>{
      Object.keys(permissions).map((it, ind) => {
        return (
         <ArrayInput key={ind} source={`permissions.${it}`} label={it} >
            <SimpleFormIterator inline fullWidth disableAdd disableClear disableRemove>
                <TextInput source='n' disabled={true} label="Name"/>
                <TextInput source='r' disabled={true} label="Route"/>
                <TextInput source='m'disabled={true} label="Method"/>
                <BooleanInput source='a' label={"Allowed"}/>
            </SimpleFormIterator>
         </ArrayInput>
        )
      })
    }
    </>
  )
}

export function CheckBoxes({ list, resource, all, setAll }) {
  const [group, setGroup] = useState(list)
  //const data = list.map(it => (it.n))
  //console.log('group......', group)
  return (
    <>
      <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
        <FormLabel component="legend">{resource}</FormLabel>
        <FormGroup>
          {
            list.map((it, ind) => {
              return (
                <FormControlLabel
                  key={ind}

                  control={
                    <CheckBoxItem item={it} group={group} setGroup={setGroup} all={all} setAll={setAll} resource={resource} />
                  }
                  label={it.n}
                />
              )
            })
          }

        </FormGroup>
      </FormControl>
      {/* <CheckboxGroupInput choices={data} defaultValue={data} source={resource} optionValue="n"  optionText="n" onChange={e=> {
        var a = {  }
        a[`${resource}`] =e
        console.log( 'admin ;;;;;;;;;;;', a)

      }}/> */}
    </>
  )
}

export function CheckBoxItem({ item, group, setGroup, all, setAll, resource }) {
  const form = useFormContext()
  const [data, setData] = useState(item);
  useEffect(() => {
    console.log("here...")
    setAll(prev => {
      prev[`${resource}`] = group
      return { ...prev }
    })
  }, [group])
  return (
    <Checkbox checked={data.a} value={data.n} name={data.n} onChange={(e) => {
      //console.log("e......", e.target.value)
      // form.setValue('data.n', '', { shouldDirty: true, shouldTouch: true })
      // if (!form.formState.isDirty) {
      //   form.formState.isDirty;
      // }
      setData((prev) => {
        return { ...prev, a: !prev.a }
      })

      console.log(e.target.value, group)
      for (let i = 0; i < group.length; i++) {

        console.log(e.target.value, group[i].n)
        if (group[i].n === e.target.value) {
          setGroup(prev => {
            group[i].a = !group[i].a
            return [...group]
          })
        }
      }

    }}
    />
  )
}