import {
    BooleanInput, Create, SimpleForm, TextInput} from "react-admin";

export const ReportReasonCreate = (props) => (
    <Create {...props}>
        <SimpleForm >
            <TextInput source="name" />
            <TextInput source="description" multiline/>
            <BooleanInput source="isActive" />
        </SimpleForm>
    </Create>
);

