import {
    ReferenceField, Show, SimpleShowLayout, TextField, useShowController
} from "react-admin";

export const ReportShow = (props) => {
    const { record } = useShowController()
    return (
        <Show {...props} title={record?.name}>
            <SimpleShowLayout>
                <ReferenceField source="reasonId" reference="report-reason">
                    <TextField source="name" />
                </ReferenceField>
                <TextField source="description" aria-multiline/>
                <TextField source="status" />
                <ReferenceField source="reportedByUserId" reference="user">
                    <TextField source="name" />
                </ReferenceField>
            </SimpleShowLayout>

        </Show>
    )
}


