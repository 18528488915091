import {
    AutocompleteInput, Create, NumberInput, ReferenceInput, SimpleForm, TextInput} from "react-admin";

export const SocietyCreate = (props) => (
    <Create {...props}>
        <SimpleForm>
            <TextInput source="name" />

            <ReferenceInput source="cityId" reference="city" queryOptions={{ meta: { nonPaginated: true } }}>
                <AutocompleteInput optionText="name" defaultValue={null} filterToQuery={(q)=>({name: q})} />
            </ReferenceInput>

            <NumberInput source="pincode" />
            <ReferenceInput source="localityId" reference="locality" queryOptions={{ meta: { nonPaginated: true } }}>
                <AutocompleteInput optionText="name" defaultValue={null} filterToQuery={(q)=>({name: q})} />
            </ReferenceInput>
        </SimpleForm>
    </Create>
);

