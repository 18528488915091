import dayjs from "dayjs";
import { useEffect, useState } from "react";
import {
    BooleanField, DateField, Show, SimpleShowLayout, TextField, useShowController, Box, useDataProvider, useRecordContext, useCreatePath
} from "react-admin";
import { Card } from "react-rainbow-components";
import { Link } from "react-admin";
import { Status } from "./list";

export const UserShow = (props) => {
    const { record } = useShowController()
    return (
        <Show {...props} title={record?.name}>
            <SimpleShowLayout>
                <TextField source="name" />
                <TextField source="phone" label="Mobile" />
                <Status source="userStatus" label="Staus" />

                <BooleanField source="isNewUser" />
                <TextField source="id" />
                <DateField source="createdAt" locales={'en-IN'} />
                <TextField source="country" />
                <TextField source="firstLoginCountry" />
                <TextField source="firstLoginIp" />
                <TextField source="lastLoginIp" />
                <PropertiesList />
            </SimpleShowLayout>
        </Show>
    )
}

function PropertiesList() {
    const [properties, setProperties] = useState([]);
    const provider = useDataProvider();
    const { record } = useShowController();
    const createPath = useCreatePath();

    useEffect(() => {
        if (record?.id) {
            provider.getList('property', {
                filter: {
                    isDraft: false,
                    isOngoingProject: false,
                    postedByUserId: record?.id

                },
                meta: { nonPaginated: true }
            }).then(({ data }) => {
                if (data) {
                    setProperties(data)
                }
            })
        }
    }, [])
    return (
        <div>
            <h2>Properties</h2>
            <div className="property-list">

                {
                    properties && properties instanceof Array && properties.map((it, index) => (
                        <Link to={createPath({ resource: 'property', type: 'show', id: it?.id })} key={index}>
                            <Card style={{ padding: "10px" }} >
                                <img width="100%" src={it?.media && it?.media instanceof Array && it?.media[0] ? it?.media[0].link : ''} alt="" />
                                <h3>{`Title : ${it?.title}`}</h3>
                                <h4>{`Posted On : ${dayjs(it?.postedOn).format("MMM DD, YYYY")}`}</h4>
                                <h4>{`Review Status : ${it?.reviewStatus}`}</h4>
                                {
                                    it?.reviewStatus === 'PASSED' && <h4>{`Sell Status : ${it?.sellStatus}`}</h4>
                                }

                            </Card>
                        </Link>
                    ))
                }

            </div>
        </div>
    )
}

