import React, { useState, useEffect } from "react"
import { useDataProvider } from "react-admin";
import { Chart, Dataset } from 'react-rainbow-components';
import { FaUsers, FaUsersSlash } from 'react-icons/fa'
import { CgMoveRight } from 'react-icons/cg'
import { MdApartment } from 'react-icons/md'

import './style.css'








export function Home(props) {
    const [users, setUsers] = useState(0);
    const [greyListed, setGreyListed] = useState(0);
    const [blackListed, setBlackListed] = useState(0);
    const [active, setActive] = useState(0);
    const [property, setProperty] = useState(0);
    const [onGoing, setOnGoing] = useState(0);

    const [activeProperty, setActiveProperty] = useState(0);
    const [sold, setSold] = useState(0);
    const [rented, setRented] = useState(0);
    const provider = useDataProvider();
    async function fetchActive() {
        provider.getList('user', {
            pagination: {
                page: 1,
                perPage: 1
            },
            filter: {
                userStatus: "ACTIVE"
            }
        }).then(({ data, total }) => {
            setActive(total)
        })
    }
    async function fetchBlackListed() {
        provider.getList('user', {
            pagination: {
                page: 1,
                perPage: 1
            },
            filter: {
                userStatus: "BLACKLISTED"
            }
        }).then(({ data, total }) => {
            setBlackListed(total)
        })
    }
    async function fetchGreylisted() {
        provider.getList('user', {
            pagination: {
                page: 1,
                perPage: 1
            },
            filter: {
                userStatus: "GREYLISTED"
            }
        }).then(({ data, total }) => {
            setGreyListed(total)
        })
    }
    async function fetchTotal() {
        provider.getList('user', {
            pagination: {
                page: 1,
                perPage: 1
            },
            filter: {
            }
        }).then(({ data, total }) => {
            setUsers(total)
        })
    }
    async function fetchProperties() {
        provider.getList('property', {
            pagination: {
                page: 1,
                perPage: 1
            },
            filter: {
                isOngoingProject: false
            }
        }).then(({ data, total }) => {
            setProperty(total)
        })
    }
    async function fetchActiveProperties() {
        provider.getList('property', {
            pagination: {
                page: 1,
                perPage: 1
            },
            filter: {
                isOngoingProject: false,
                sellStatus: "ACTIVE",
                isDraft: false
            }
        }).then(({ data, total }) => {
            setActiveProperty(total)
        })
    }
    async function fetchSoldProperties() {
        provider.getList('property', {
            pagination: {
                page: 1,
                perPage: 1
            },
            filter: {
                isOngoingProject: false,
                sellStatus: ["SOLD"],
                isDraft: false
            }
        }).then(({ data, total }) => {
            setSold(total)
        })
    }
    async function fetchRentedProperties() {
        provider.getList('property', {
            pagination: {
                page: 1,
                perPage: 1
            },
            filter: {
                isOngoingProject: false,
                sellStatus: ["RENTED"],
                isDraft: false
            }
        }).then(({ data, total }) => {
            setRented(total)
        })
    }

    async function fetchOngoing() {
        provider.getList('onGoingProject', {
            pagination: {
                page: 1,
                perPage: 1
            },
            filter: {
                isOngoingProject: true
            }
        }).then(({ data, total }) => {
            setOnGoing(total)
        })
    }

    useEffect(() => {
        fetchActive();
        fetchBlackListed();
        fetchGreylisted();
        fetchTotal();
        fetchProperties();
        fetchOngoing();
        fetchActiveProperties();
        fetchSoldProperties();
        fetchRentedProperties();
    }, [])

    console.log('user data', { users, blackListed, greyListed, active })


    return (
        <>
            <div className="container">
                <div className="data-section">
                    <div className="entity">
                        <h2>Users</h2>
                        <div className="entity-cards">
                            <div className="card">
                                <div className="upper">
                                    <FaUsers size={70} color="white" />
                                    <div className="data">
                                        <span>{users}</span>
                                        <p>Users</p>
                                    </div>
                                </div>
                                <div className="lower">
                                    <h3>See All</h3>
                                    <a href="/#/user"><CgMoveRight size={30} /></a>

                                </div>
                            </div>
                            <div className="card-green">
                                <div className="upper-green">
                                    <FaUsers size={70} color="white" />
                                    <div className="data">
                                        <span>{active}</span>
                                        <p> Active Users</p>
                                    </div>
                                </div>
                                <div className="lower">
                                    <h3>See All</h3>
                                    <a href="/#/user">
                                        <CgMoveRight size={30} />
                                    </a>
                                </div>
                            </div>
                            <div className="card-grey">
                                <div className="upper-grey">
                                    <FaUsers size={70} color="white" />
                                    <div className="data">
                                        <span>{greyListed}</span>
                                        <p> Greylisted Users</p>
                                    </div>
                                </div>
                                <div className="lower">
                                    <h3>See All</h3>
                                    <a href="/#/user"><CgMoveRight size={30} /></a>
                                </div>
                            </div>

                            <div className="card-black">
                                <div className="upper-black">
                                    <FaUsersSlash size={70} color="white" />
                                    <div className="data">
                                        <span>{blackListed}</span>
                                        <p> Blacklisted Users</p>
                                    </div>
                                </div>
                                <div className="lower">
                                    <h3>See All</h3>
                                    <a href="/#/user"><CgMoveRight size={30} /></a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="entity">
                        <h2>Properties</h2>
                        <div className="entity-cards">
                            <div className="card">
                                <div className="upper">
                                    <MdApartment size={70} color="white" />
                                    <div className="data">
                                        <span>{property}</span>
                                        <p>Properties</p>
                                    </div>
                                </div>
                                <div className="lower">
                                    <h3>See All</h3>
                                    <a href="/#/property">
                                        <CgMoveRight size={30} />
                                    </a>
                                </div>
                            </div>
                            <div className="card-green">
                                <div className="upper-green">
                                    <MdApartment size={70} color="white" />
                                    <div className="data">
                                        <span>{activeProperty}</span>
                                        <p> Active Properties</p>
                                    </div>
                                </div>
                                <div className="lower">
                                    <h3>See All</h3>
                                    <a href="/#/property">
                                        <CgMoveRight size={30} />
                                    </a>
                                </div>
                            </div>
                            <div className="card">
                                <div className="upper">
                                    <MdApartment size={70} color="white" />
                                    <div className="data">
                                        <span>{sold}</span>
                                        <p>Sold Properties</p>
                                    </div>
                                </div>
                                <div className="lower">
                                    <h3>See All</h3>
                                    <a href="/#/property">
                                        <CgMoveRight size={30} />
                                    </a>
                                </div>
                            </div>
                            <div className="card-green">
                                <div className="upper">
                                    <MdApartment size={70} color="white" />
                                    <div className="data">
                                        <span>{rented}</span>
                                        <p>Rented Properties</p>
                                    </div>
                                </div>
                                <div className="lower">
                                    <h3>See All</h3>
                                    <a href="/#/property">
                                        <CgMoveRight size={30} />
                                    </a>
                                </div>
                            </div>

                        </div>
                    </div>


                    <div className="entity">
                        <h2>Ongoing  Projects</h2>
                        <div className="entity-cards">

                            <div className="card">
                                <div className="upper">
                                    <MdApartment size={70} color="white" />
                                    <div className="data">
                                        <span>{onGoing}</span>
                                        <p>Ongoing Projects</p>
                                    </div>
                                </div>
                                <div className="lower">
                                    <h3>See All</h3>
                                    <a href="/#/ongoingProject">
                                        <CgMoveRight size={30} />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>






                </div>


                <div className="chart-section">
                    <Chart labels={['Active User', 'Black Listed', "GreyListed"]} type="pie" legendPosition="right">
                        <Dataset title="Data" values={[active, blackListed, greyListed]} backgroundColor={['green', 'black', 'grey']} />
                    </Chart>
                </div>
            </div>
        </>

    )
}



