import { fetchUtils } from "react-admin";

import { api } from "../api";

export  const httpClient = (url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: "application/json" });
    options.headers.set("Access-Control-Allow-Origin", "*");
    options.headers.set("type", "admin");
  }
  let at = localStorage.getItem("accessToken");
  if (at && at !== "undefined" && at !== "null") {
    options.headers.set(
      "Authorization",
      `Bearer ${localStorage.getItem("accessToken")}`
    );
  }
  return fetchUtils.fetchJson(url, options);
};

const dataProvider = {
  create: (resource, params) => {
    return httpClient(`${api}/${resource}`, {
      method: "POST",
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({
      data: { ...params.data, id: json.id },
    }));
  },

  getOne: (resource, params) => {
    return httpClient(`${api}/${resource}/${params.id}`).then(({ json }) => ({
      data: json.result,
    }));
  },

  getMany: (resource, params) => {
    const query = { id: params.ids };
    const search = new URLSearchParams();

    Object.keys(query).forEach((value) => {
      if (query[value] instanceof Array) {
        query[value].forEach((v) => search.append(value, v));
      } else {
        search.append(value, query[value]);
      }
    });
    search.append("nonPaginated", true);

    const url = `${api}/${resource}?${search.toString()}`;
    return httpClient(url).then(({ json }) => ({ data: json.result }));

    // const url = `${api}/${resource}?nonPaginated=true&${query.id}`
    // return httpClient(url).then(({ json }) => ({ data: json.result }));
  },

  update: (resource, params) => {
    return httpClient(`${api}/${resource}/${params.id}`, {
      method: "PUT",
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({ data: params.data }));
  },

  getList: (resource, params) => {
    const { sort, filter } = params;
    // const url = `${api}/${resource}/all?skip=${(skip - 1) * limit}&limit=${limit}`;
    const search = new URLSearchParams();

    // Search params based on pagination
    if (params && params.meta && params.meta.nonPaginated) {
      search.set("nonPaginated", params.meta.nonPaginated);
    } else if (params.pagination) {
      if (params.pagination.page) {
        search.set("page", params.pagination.page);
      }
      if (params.pagination.perPage) {
        search.set("count", params.pagination.perPage || 25);
      } else {
        search.set("count", 25);
      }
      if (sort && sort.field) {
        search.set("orderBy", sort.field ?? "name");
      }
      if (sort && sort.order) {
        search.set("order", sort.order);
      }
    }
    // Search Params based on filters
    if (params.filter) {
      if (params.filter.like) {
        Object.keys(params.filter.like).forEach((key) => {
          console.log(params.filter.like[key]);
          if (!params.filter.like[key].includes("%")) {
            search.append(`like[${key}]`, `%${params.filter.like[key]}%`);
          } else {
            search.append(`like[${key}]`, params.filter.like[key]);
          }
        });
        delete params.filter.like;
      }

      if (params.filter.name) {
        search.append(`like[name]`, `%${params.filter.name}%`);
      }
      if (params.filter.username) {
        search.append(`like[username]`, `%${params.filter.username}%`);
      }
      if (params.filter.email) {
        search.append(`like[email]`, `%${params.filter.email}%`);
      }
      if (params.filter.pincode) {
        search.append(`like[pincode]`, `%${params.filter.pincode}%`);
      }
      if (params.filter.phone) {
        search.append(`like[phone]`, `%${params.filter.phone}%`);
      }
      if (params.filter.label) {
        search.append(`like[label]`, `%${params.filter.label}%`);
      }
      if (params.filter.slug) {
        search.append(`like[slug]`, `%${params.filter.slug}%`);
      }
      if (params.filter.unit) {
        search.append(`like[unit]`, `%${params.filter.unit}%`);
      }
      if (params.filter.key) {
        search.append(`like[key]`, `%${params.filter.key}%`);
      }
      Object.keys(params.filter).forEach((key) => {
        if (params.filter[key] instanceof Array) {
          params.filter[key].forEach((it) => {
            search.append(key, it);
          });
        } else {
          search.append(key, params.filter[key]);
        }
      });
    }
    if (params.meta && params.meta.filter) {
      Object.keys(params.meta.filter).forEach((key) => {
        search.append(key, params.meta.filter[key]);
      });
    }
    const url = `${api}/${resource}?${search.toString()}`;
    return httpClient(url)
      .then(({ headers, json }) => {
        return {
          data: json.result ?? [],
          total: json.count ?? 0,
        };
      })
      .catch(function (error) {
        if (
          error.status === 404 &&
          error.body &&
          typeof error.body === "object"
        ) {
          return {
            data: error.body.result ?? [],
            total: error.body?.result?.length() ?? 0,
          };
        }
        return {
          data: [],
          total: 0,
        };
      });
  },

  delete: (resource, params) => {
    return httpClient(`${api}/${resource}/${params.id}`, {
      method: "DELETE",
    }).then(({ json }) => ({ data: json }));
  },
  getManyReference: (resource, params) => {
    const { sort } = params;
    // const url = `${api}/${resource}/all?skip=${(skip - 1) * limit}&limit=${limit}`;
    const search = new URLSearchParams();
    // Search params based on pagination
    if (params.pagination) {
      if (!params.pagination.nonPaginated) {
        if (params.pagination.page) {
          search.set("page", params.pagination.page);
        }
        if (params.pagination.perPage) {
          search.set("count", params.pagination.perPage || 10);
        } else {
          search.set("count", 10);
        }
        if (sort && sort.field) {
          search.set("orderBy", sort.field ?? "name");
        }
        if (sort && sort.order) {
          search.set("order", sort.order);
        }
      } else {
        search.set("nonPaginated", false);
      }
    } else {
      search.set("nonPaginated", true);
    }

    // Search Params based on filters
    if (params.target) {
      search.append(params.target, params.value || params.id);
    }
    return httpClient(`${api}/${resource}?${search.toString()}`, {
      method: "GET",
    }).then(({ json }) => ({ data: json.result, total: json.count }));
  },
  mergeSociety: (data) => {
    return httpClient(`${api}/society/merge`, {
      method: "POST",
      body: JSON.stringify(data),
    })
      .then(({ json }) => {
        // console.log(json, "provider");
        return { data: json, message: json.message, status: json.status };
      })
      .catch((e) => {
        console.log("error", e);
      });
  },
  mergeLocality: (data) => {
    return httpClient(`${api}/locality/merge`, {
      method: "POST",
      body: JSON.stringify(data),
    }).then(({ json }) => {
      // console.log(json, "provider");
      return { data: json, message: json.message , status: json.status};
    });
  },
};

export default dataProvider;
