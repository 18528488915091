import {
    ArrayField,
    ArrayInput, BooleanField, Create, Datagrid, Edit, List, Show, SimpleForm, SimpleFormIterator, SimpleShowLayout, TextField, TextInput, useEditController, useShowController
} from "react-admin";

export const LoclityShow = (props) => {
    const { record } = useShowController()
    return (
        <Show {...props} title={record?.name}>
            <SimpleShowLayout>
                <TextField source="name" />
                <TextField source="id" label="State Id"/>
            </SimpleShowLayout>
        </Show>
    )
}


