import { DeleteButton, SaveButton, Toolbar } from "react-admin";


export const EditToolbar = props => (
    <Toolbar {...props} >
        <SaveButton />
    </Toolbar>
);


export const EditAndDeleteToolbar = props => {
    return (
        <Toolbar {...props} sx={{display: 'flex', justifyContent: 'space-between'}} >
            <SaveButton />
            {/* <DeleteButton /> */}
        </Toolbar>
    )
}