
import {
   BooleanField,  Datagrid,  List, TextField, TextInput
} from "react-admin";

const Filters = [
    <TextInput source='name' alwaysOn={true} />,

]


export const CarouselList = (props) => (
    <List {...props} filters={Filters}>
        <Datagrid rowClick='show'>
            <TextField source="name" />
            <TextField source="type" />
            <BooleanField source='isVisible' />
        </Datagrid>
    </List>
)
