
import {
    AutocompleteInput, Datagrid, List, ReferenceField, ReferenceInput, SelectInput, TextField
} from "react-admin";

const Filters = [
    <ReferenceInput source="reasonId" reference="report-reason" alwaysOn={true} >
        <AutocompleteInput optionText="name" filterToQuery={(q) => ({ name: q })} />
    </ReferenceInput>,
    <SelectInput source='status' choices={[
        {
            id: "REGISTERED",
            name: "Registered"
        },
        {
            id: "SEEN",
            name: "Seen"
        },
        {
            id: "REJECTED",
            name: "Rejected"
        },
        {
            id: "ADDITIONAL_DETAILS_REQUIRED",
            name: "Additional Details Required"
        },
        {
            id: "ACTION_INITIATED",
            name: "Action Initiated"
        },
        {
            id: "ACTION_PENDING",
            name: "Action Pending"
        },
        {
            id: "RESOLVED",
            name: "Resolved"
        },
    ]} alwaysOn={true} />,
    <ReferenceInput source="reportedByUserId" reference="user" alwaysOn={true} >
        <AutocompleteInput optionText="name" label="Reported By" filterToQuery={(q) => ({ name: q })} />
    </ReferenceInput>,
    <ReferenceInput source="reportedAgainstPropertyId" reference="property" alwaysOn={true}  >
        <AutocompleteInput optionText="title" label="Reported Property" filterToQuery={(q) => ({ title: q })} />
    </ReferenceInput>
]




export const ReportList = (props) => (
    <List {...props} filters={Filters}>
        <Datagrid rowClick='show' >
            <ReferenceField source="reasonId" reference="report-reason">
                <TextField source="name" />
            </ReferenceField>
            <TextField source="status" />
            <ReferenceField source="reportedByUserId" reference="user">
                <TextField source="name" />
            </ReferenceField>
            <ReferenceField source="reportedAgainstPropertyId" reference="property"  >
                <TextField source="title" />
            </ReferenceField>
            <ReferenceField source="reportedAgainstUserId" reference="user"  >
                <TextField source="name" />
            </ReferenceField>
            <ReferenceField source="reportedAgainstUserId" reference="user" label="Reported Against"  >
                <TextField source="phone" />
            </ReferenceField>
        </Datagrid>
    </List>
)
