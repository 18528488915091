import { Chip } from "@mui/material";
import {
    ArrayField,
    ArrayInput, BooleanField, Create, Datagrid, Edit, List, Show, SimpleForm, SimpleFormIterator, SimpleShowLayout, TextField, TextInput, useEditController, useRecordContext, useShowController
} from "react-admin";

export const AdministratorShow = (props) => {
    const { record } = useShowController()
    return (
        <Show {...props} title={record?.name}>
            <SimpleShowLayout>
                <TextField source="username" />
                <TextField source="phone" />
                <TextField source="email" />
                <BooleanField source="isSuperAdmin" /><Permissions />
            </SimpleShowLayout>
        </Show>
    )
}


export const Permissions = (props) => {
    const record = useRecordContext()
    console.log("record", record)
    const permissions = record?.permissions || {}
    return (
        <>
            <b>Permissions</b>
            {
                Object.keys(permissions).map((it, ind) => {
                    return (
                        <div key={ind}>
                            <p>{it}</p>
                            <PermissionList list={permissions[`${it}`]} />
                        </div>
                    )
                })
            }
        </>
    )
}

const PermissionList = ({ list }) => {
    const data = list || []
    return (
        <>
            {
                data.length > 0 && data?.map((it, ind) => {
                    return (
                        <>
                            <Chip key={ind} label={it.n} sx={{ margin: "5px" }} color={it.a ? 'success' : 'error'}  ></Chip>
                            {/* <Chip className="rainbow-m-around_medium" key={ind} label={it.n} variant={it.a ? 'success' : 'error'}  /> */}
                        </>
                    )
                })
            }
        </>
    )
}


