import { ToWords } from "to-words";

export const toWords = new ToWords({
  localeCode: "en-IN",
  converterOptions: {
    currency: true,
    ignoreDecimal: false,
    ignoreZeroCurrency: false,
    doNotAddOnly: true,
    currencyOptions: {
      // can be used to override defaults for the selected locale
      name: "Rupee",
      plural: "Rupees",
      symbol: "₹",
      fractionalUnit: {
        name: "Paisa",
        plural: "Paise",
        symbol: "",
      },
    },
  },
});



export const ConfigurationChoices = [
  {
    id: "1RK",
    name: "1RK",
  },
  {
    id: "1BHK",
    name: "1BHK",
  },
  {
    id: "2BHK",
    name: "2BHK",
  },
  {
    id: "3BHK",
    name: "3BHK",
  },
  {
    id: "4BHK",
    name: "4BHK",
  },
  {
    id: "OTHER",
    name: "OTHER",
  },
];

export const FoodHabitChoices = [
  {
    id: "VEG",
    name: "Veg",
  },
  {
    id: "NON_VEG",
    name: "Non Veg",
  },
];

export const OwnerShipChoices = [
  {
    id: "FREE_HOLD",
    name: "Freehold",
  },
  {
    id: "LEASE_HOLD",
    name: "Lease Hold",
  },
  {
    id: "POWER_ATTORNEY",
    name: "Power Attorney",
  },
];

export const OverLookinType = [
  {
    id: "CLUB",
    name: "Club",
  },
  {
    id: "GARDEN_OR_PARK",
    name: "Garden or Park",
  },
  {
    id: "ROAD",
    name: "Road",
  },
  {
    id: "SWIMMING_POOL",
    name: "Swimming Pool",
  },
  {
    id: "CENTRAL_PARK",
    name: "Central Park",
  },
  {
    id: "GOLF",
    name: "Golf",
  },
  {
    id: "FOREST",
    name: "Forest",
  },
  {
    id: "RIVER",
    name: "River",
  },
  {
    id: "LAKE",
    name: "Lake",
  },

  {
    id: "BEACH",
    name: "Freehold",
  },

  {
    id: "HILL_VIEW",
    name: "Freehold",
  },
];

export const ExtraRoomChoices = [
  { id: "SERVANT_ROOM", name: "Servent Room" },
  { id: "STUDY_ROOM", name: "Study Room" },
  { id: "STORE_ROOM", name: "Store Room" },
  { id: "POOJA_ROOM", name: "Pooja Room" },
  { id: "TERRACE", name: "Terrace" },
  { id: "ROOF", name: "Roof" },
  { id: "LAWN", name: "Lawn" },
  { id: "GARAGE", name: "Garage" },
  { id: "BASEMENT", name: "Basement" },
  { id: "BACKYARD", name: "Backyard" },
  { id: "LAUNDRY_ROOM", name: "Laundry Room" },
  { id: "SERVANT_ROOM_2", name: "Servent Room 2" },
];

export const PropertyTypeChoices = [
  {
    id: "HIGH_RISE_APPARTMENT",
    name: "High Rise Apartment",
  },
  {
    id: "MID_RISE_APPARTMENT",
    name: "Mid Rise Apartment",
  },
  {
    id: "BULDER_FLOORS",
    name: "Builder Floors",
  },
  {
    id: "VILLA_SIMPLEX",
    name: "Villa Simplex",
  },
  {
    id: "VILLA_DUPLEX",
    name: "Villa Duplex",
  },
  {
    id: "KOTHI_SIMPLEX",
    name: "Kothi Simplex",
  },
  {
    id: "KOTHI_DUPLEX",
    name: "Kothi Duplex",
  },
  {
    id: "PENTHOUSE_SIMPLEX",
    name: "Penthouse Simplex",
  },
  {
    id: "PENTHOUSE_DUPLEX",
    name: "Penthouse Duplex",
  },
  {
    id: "ROW_HOUSE_SIMPLEX",
    name: "Row House Simplex",
  },
  {
    id: "ROW_HOUSE_DUPLEX",
    name: "Row House Duplex",
  },
  {
    id: "BASEMENT",
    name: "Basement",
  },
  {
    id: "PLOT",
    name: "Plot",
  },
];

export const SellStatusTypeChoices = [
  {
    id: "ACTIVE",
    name: "Active",
  },
  {
    id: "SOLD",
    name: "Sold",
  },
  {
    id: "RENTED",
    name: "Rented",
  },
];

export const FurnishingStateChoices = [
  {
    id: "FULLY_FURNISHED",
    name: "Fully Furnished",
  },
  {
    id: "SEMI_FURNISHED",
    name: "Semi furnished",
  },
  {
    id: "UNFURNISHED",
    name: "Unfurnished",
  },
];

export const PowerBackupChoices = [
  {
    id: "FULL_BACKUP",
    name: "Full Backup",
  },
  {
    id: "PARTIAL_BACKUP",
    name: "Partial Backup",
  },
  {
    id: "LIFT_ONLY_BACKUP",
    name: "Lift Only Backup",
  },
  {
    id: "INVERTER",
    name: "Inverter",
  },
  {
    id: "NO_BACKUP",
    name: "No Backup",
  },
];

export const ReviewStausChoices = [
  { id: "UNDER_REVIEW", name: "Under Review" },
  { id: "PASSED", name: "Passed" },
  { id: "REJECTED", name: "Rejected" },
];

export const AreaUnitChoices = [
  { id: "SquareFeet", name: "sq. ft." },
  { id: "SquareYard", name: "sq. yd." },
  { id: "SquareMeter", name: "sq. mtr." },
];

export function getTextFromEnum(text) {
  const arr = text.split("_");
  if (arr instanceof Array) {
    const newArray = arr.map((it, index) => {
      return it.charAt(0).toUpperCase() + it.slice(1).toLowerCase();
    });
    return newArray.join(" ");
  }
  return "";
}

export const defaultAmenities = {
  club: [
    {
      id: "1",
      isAvailabele: false,
      name: "Club House",
      image: {
        link: "https://nobrokerage.s3.ap-south-1.amazonaws.com/property/fcfabd2f293b0157719c58c217c8cbb53689bd2454872165e12b9af7b5747851.png",
        name: "c1.png",
        reference: "",
      },
    },
    {
      id: "2",
      isAvailabele: false,
      name: "Swimming Pool( Outdoor)",
      image: {
        link: "https://nobrokerage.s3.ap-south-1.amazonaws.com/property/6954bf731b3c84aa7474ed8a9d6bd632feaf2d13f9fcf67aeda5f750db84b3e4.png",
        name: "c2.png",
        reference: "",
      },
    },
    {
      id: "3",
      isAvailabele: false,
      name: "Swimming Pool( Indoor )",
      image: {
        link: "https://nobrokerage.s3.ap-south-1.amazonaws.com/property/87e61108357bc182432f0f85636264f9b31b56648506aab46609dee789f15708.png",
        name: "c3.png",
        reference: "",
      },
    },
    {
      id: "4",
      isAvailabele: false,
      name: "Pool Deck Area",
      image: {
        link: "https://nobrokerage.s3.ap-south-1.amazonaws.com/property/0290061052b762bb0d17bf54c89dd14b2eab09c9644d85a924615b3cf0a28c00.png",
        name: "c4.png",
        reference: "",
      },
    },
    {
      id: "5",
      isAvailabele: false,
      name: "Library",
      image: {
        link: "https://nobrokerage.s3.ap-south-1.amazonaws.com/property/c31fa877d057d0933977a53077c34ac13625bf4539a4c2926e762700a1568aeb.png",
        name: "c5.png",
        reference: "",
      },
    },
    {
      id: "6",
      isAvailabele: false,
      name: "Party Room Big",
      image: {
        link: "https://nobrokerage.s3.ap-south-1.amazonaws.com/property/7ee061e9af9a362a6b1feba735613fd6fea7b54c85ff37bce52fc4ef59e0169c.png",
        name: "c6.png",
        reference: "",
      },
    },
    {
      id: "7",
      isAvailabele: false,
      name: "Party Room Small",
      image: {
        link: "https://nobrokerage.s3.ap-south-1.amazonaws.com/property/e21ed0b1ababe5dd00dc792d57747df1465864dba6b22b9583bd28a3255cefd2.png",
        name: "c7.png",
        reference: "",
      },
    },
    {
      id: "8",
      isAvailabele: false,
      name: "Roof Deck  Lounge",
      image: {
        link: "https://nobrokerage.s3.ap-south-1.amazonaws.com/property/1a7c1a6c35719092b2302b131c9179a17a1c3b3d2423017733c3739db91b1f55.png",
        name: "c8.png",
        reference: "",
      },
    },
    {
      id: "9",
      isAvailabele: false,
      name: "Steam Bathroom",
      image: {
        link: "https://nobrokerage.s3.ap-south-1.amazonaws.com/property/bcbeb0ba254235ee6ad0c54dcd6d0d566a9fb4e08fe30ed0a8e2478a4b47100c.png",
        name: "c11.png",
        reference: "",
      },
    },
    {
      id: "10",
      isAvailabele: false,
      name: "Card Room",
      image: {
        link: "https://nobrokerage.s3.ap-south-1.amazonaws.com/property/4ae930f6f5d92887be27637468d606c99013fea397aa76d26d9ad783c84a1fa7.png",
        name: "c12.png",
        reference: "",
      },
    },
    {
      id: "11",
      isAvailabele: false,
      name: "Sun Deck",
      image: {
        link: "https://nobrokerage.s3.ap-south-1.amazonaws.com/property/e8cc99c28d64df74f7bdb612434f3a24b5cd4cc40b1c9ffad00ef5cdc2de6331.png",
        name: "c13.png",
        reference: "",
      },
    },
    {
      id: "12",
      isAvailabele: false,
      name: "Spa Room",
      image: {
        link: "https://nobrokerage.s3.ap-south-1.amazonaws.com/property/413b74ccda3832588d5368ad007349764bc7fc8a4a24a22127c1c17d841b40e2.png",
        name: "c10.png",
        reference: "",
      },
    },
  ],
  sportsAndFitness: [
    {
      id: "13",
      isAvailabele: false,
      name: "Cycle Track",
      image: {
        link: "https://nobrokerage.s3.ap-south-1.amazonaws.com/property/ed8eb74d771bcf6b92778ccae53628e66bb6b9755355f852a0ef90e63153b5c1.png",
        name: "s1.png",
        reference: "",
      },
    },
    {
      id: "14",
      isAvailabele: false,
      name: "Dance room",
      image: {
        link: "https://nobrokerage.s3.ap-south-1.amazonaws.com/property/1dae7412176b7f72350682a833253ee7ba8fedca7b3366bfa12e2d8f73b4bd98.png",
        name: "s2.png",
        reference: "",
      },
    },
    {
      id: "15",
      isAvailabele: false,
      name: "Garden Gym",
      image: {
        link: "https://nobrokerage.s3.ap-south-1.amazonaws.com/property/5b7700e7cf7dbc65d61dd47a9c5bffa3ab20e60a8b62ed8b2eee3ccedc3d2f7b.png",
        name: "s3.png",
        reference: "",
      },
    },
    {
      id: "16",
      isAvailabele: false,
      name: "Basket Ball  Court",
      image: {
        link: "https://nobrokerage.s3.ap-south-1.amazonaws.com/property/86eff440c00c8e4c10e13f6a23429bf6bb8ddfb33197b08bafc7ce60f199ddf3.png",
        name: "s4.png",
        reference: "",
      },
    },
    {
      id: "17",
      isAvailabele: false,
      name: "Health Club",
      image: {
        link: "https://nobrokerage.s3.ap-south-1.amazonaws.com/property/b548c8ae8ca5d5984b9b7dfc1ab54e5a565156e2c073c69929c7e92dc8bdab9d.png",
        name: "s5.png",
        reference: "",
      },
    },
    {
      id: "18",
      isAvailabele: false,
      name: "Jogging Track",
      image: {
        link: "https://nobrokerage.s3.ap-south-1.amazonaws.com/property/bb5abf40cdee8567265dff8d027799f0bc27e789cbf9beefc646558eabeba6ed.png",
        name: "s7.png",
        reference: "",
      },
    },
    {
      id: "19",
      isAvailabele: false,
      name: "Yoga",
      image: {
        link: "https://nobrokerage.s3.ap-south-1.amazonaws.com/property/68884df877258022140ecd587d357801a87e388b921ec6978105f0dd40592318.png",
        name: "s8.png",
        reference: "",
      },
    },
    {
      id: "20",
      isAvailabele: false,
      name: "Badminton ( Indoor)",
      image: {
        link: "https://nobrokerage.s3.ap-south-1.amazonaws.com/property/9e1accbe61038aa1db76a472839931ae681dabc9121685b0ccbd241c514fd208.png",
        name: "s9.png",
        reference: "",
      },
    },
    {
      id: "21",
      isAvailabele: false,
      name: "Badminton (Outdoor)",
      image: {
        link: "https://nobrokerage.s3.ap-south-1.amazonaws.com/property/cca229b728accfba5f5ec120471b54e46d3402fb696909caf184482408e482e0.png",
        name: "s10.png",
        reference: "",
      },
    },
    {
      id: "22",
      isAvailabele: false,
      name: "Basketball",
      image: {
        link: "https://nobrokerage.s3.ap-south-1.amazonaws.com/property/0a190af79e89dc5ea1a5f8ff562c1aa0a4e16744cf99f4c018a2856ea0d2cb72.png",
        name: "s11.png",
        reference: "",
      },
    },
    {
      id: "23",
      isAvailabele: false,
      name: "Bowling Alley",
      image: {
        link: "https://nobrokerage.s3.ap-south-1.amazonaws.com/property/07500ab3bf6bdb1e9f9619db0d3a78c96fa3d9629b39e8cc96a09f980bd693fc.png",
        name: "s12.png",
        reference: "",
      },
    },
    {
      id: "24",
      isAvailabele: false,
      name: "Cricket Ground",
      image: {
        link: "https://nobrokerage.s3.ap-south-1.amazonaws.com/property/ebe9884df30f9d51077d314e82139a96f9c3ab12793cdf14d04cee704b3d6fe5.png",
        name: "s13.png",
        reference: "",
      },
    },
    {
      id: "25",
      isAvailabele: false,
      name: "Football Ground",
      image: {
        link: "https://nobrokerage.s3.ap-south-1.amazonaws.com/property/1377dc25f00529009039dfd474cdc7417d3781c158e2da0a38984ebaea875d37.png",
        name: "s14.png",
        reference: "",
      },
    },
    {
      id: "26",
      isAvailabele: false,
      name: "Golf Pitch",
      image: {
        link: "https://nobrokerage.s3.ap-south-1.amazonaws.com/property/0e66b0e8aabfab8eadce52a39789f2aba3dc4fe6468b90f87466af257bc8d5a0.png",
        name: "s15.png",
        reference: "",
      },
    },
    {
      id: "27",
      isAvailabele: false,
      name: "Horseback Riding",
      image: {
        link: "https://nobrokerage.s3.ap-south-1.amazonaws.com/property/0662c8bb13da432a28a78fe4e893f8178e87ca51f3ecabb015c1c24e5db42b84.png",
        name: "s16.png",
        reference: "",
      },
    },
    {
      id: "28",
      isAvailabele: false,
      name: "Pool Table",
      image: {
        link: "https://nobrokerage.s3.ap-south-1.amazonaws.com/property/ec5719449372f5ce375c0deaf6f32005b104ff40a3e46755187928430ed63acd.png",
        name: "s17.png",
        reference: "",
      },
    },
    {
      id: "29a",
      isAvailabele: false,
      name: "Rock Climbing",
      image: {
        link: "https://nobrokerage.s3.ap-south-1.amazonaws.com/property/bcaba2e282ad9f41261bd31ef5f81df7b6aa38b262837a4234f62a840e34a5dc.png",
        name: "s18.png",
        reference: "",
      },
    },
    {
      id: "29",
      isAvailabele: false,
      name: "Skating Ring",
      image: {
        link: "https://nobrokerage.s3.ap-south-1.amazonaws.com/property/b4c8d2b489cc88af9fa6b184ddc963021982c5879b387aee3a2f4a227e961de3.png",
        name: "s19.png",
        reference: "",
      },
    },
    {
      id: "30",
      isAvailabele: false,
      name: "Squash",
      image: {
        link: "https://nobrokerage.s3.ap-south-1.amazonaws.com/property/e3468d5332092bef2b6fffd25453bfc598143d9c80a990e1f7742b0e4c080ce3.png",
        name: "s20.png",
        reference: "",
      },
    },
    {
      id: "31",
      isAvailabele: false,
      name: "Tennis",
      image: {
        link: "https://nobrokerage.s3.ap-south-1.amazonaws.com/property/d8e43cf1e482c65383ab797999b4f2934e30a90ad43df2570f9d65cef2d75649.png",
        name: "s21.png",
        reference: "",
      },
    },
    {
      id: "32",
      isAvailabele: false,
      name: "Table Tennis",
      image: {
        link: "https://nobrokerage.s3.ap-south-1.amazonaws.com/property/5a0095e0f35684f5c6afc7bfb21e77ca6f0817d1e84cfcf09692ed3d59b15383.png",
        name: "s22.png",
        reference: "",
      },
    },
    {
      id: "33",
      isAvailabele: false,
      name: "Volleyball",
      image: {
        link: "https://nobrokerage.s3.ap-south-1.amazonaws.com/property/7c6153678da0ffe1876464b86b8df68f082378d7027f3c461c3625fb96ccd6c0.png",
        name: "s23.png",
        reference: "",
      },
    },
  ],
  forChildren: [
    {
      id: "34",
      isAvailabele: false,
      name: "Kids Pool",
      image: {
        link: "https://nobrokerage.s3.ap-south-1.amazonaws.com/property/9c8ce14c462be4c04c11cce1301a8871aa050c769f433600ac0b53552bf1a599.png",
        name: "ch1.png",
        reference: "",
      },
    },
    {
      id: "35",
      isAvailabele: false,
      name: "Toddler Pool",
      image: {
        link: "https://nobrokerage.s3.ap-south-1.amazonaws.com/property/47ededc509db2735d4a5e1d5ad96a6ab9a2157a837d5f25f443e0930d2da7916.png",
        name: "ch2.png",
        reference: "",
      },
    },
    {
      id: "36",
      isAvailabele: false,
      name: "Heated Lap Pool",
      image: {
        link: "https://nobrokerage.s3.ap-south-1.amazonaws.com/property/d81249f66b4c90ffb95faa965d7baddfb7ce9065e1b1eba3f0c68b9f014cec65.png",
        name: "ch3.png",
        reference: "",
      },
    },
    {
      id: "37",
      isAvailabele: false,
      name: "Children Play Area (Indoor)",
      image: {
        link: "https://nobrokerage.s3.ap-south-1.amazonaws.com/property/d5fd614fa3ecf0f0e4b7c7634cf8ddbcbdd7f707efac960729fc01be8055d832.png",
        name: "ch4.png",
        reference: "",
      },
    },
    {
      id: "38",
      isAvailabele: false,
      name: "Childeren Play Area (Outdoor)",
      image: {
        link: "https://nobrokerage.s3.ap-south-1.amazonaws.com/property/40f5ab0ed159967be6baa7b01edce2caf4fa944d90133f8fa50bdc927cc9a482.png",
        name: "ch5.png",
        reference: "",
      },
    },
    {
      id: "39",
      isAvailabele: false,
      name: "Creche &  Daycare",
      image: {
        link: "https://nobrokerage.s3.ap-south-1.amazonaws.com/property/6a3585666f803c833c9ff6e9d6b68ae8e74ad50e7515e39fe8b81eb405fdbaaa.png",
        name: "ch6.png",
        reference: "",
      },
    },
    {
      id: "40",
      isAvailabele: false,
      name: "Miniature Golf",
      image: {
        link: "https://nobrokerage.s3.ap-south-1.amazonaws.com/property/cf6646c7c57edb5728a61aae0024ea169debef5520ec4e3800ef0d5aec075220.png",
        name: "ch7.png",
        reference: "",
      },
    },
    {
      id: "41",
      isAvailabele: false,
      name: "Playschool",
      image: {
        link: "https://nobrokerage.s3.ap-south-1.amazonaws.com/property/c201972821bd3f570e0812da0570abcfe15ac3cb92947041ca2676724a1e0955.png",
        name: "ch8.png",
        reference: "",
      },
    },
    {
      id: "42",
      isAvailabele: false,
      name: "Pre Primary School",
      image: {
        link: "https://nobrokerage.s3.ap-south-1.amazonaws.com/property/f6c4f5bab0c078517ad44cfd97e6685440f34b707b922f257a26e86e1b4380a7.png",
        name: "ch9.png",
        reference: "",
      },
    },
    {
      id: "43",
      isAvailabele: false,
      name: "Primary School",
      image: {
        link: "https://nobrokerage.s3.ap-south-1.amazonaws.com/property/e7df5cca57b8a97b20c423b8f94b150c1f4feb8948bbc18a31a385820ac6d565.png",
        name: "ch11.png",
        reference: "",
      },
    },
  ],
  safetyAndSecurity: [
    {
      id: "44",
      isAvailabele: false,
      name: "CCTV",
      image: {
        link: "https://nobrokerage.s3.ap-south-1.amazonaws.com/property/d5e1b79231882337c8d15eb929558b2567542e1a19e163c965c46f6bd0f3ef8f.png",
        name: "saf1.png",
        reference: "",
      },
    },
    {
      id: "45",
      isAvailabele: false,
      name: "Fire Safety",
      image: {
        link: "https://nobrokerage.s3.ap-south-1.amazonaws.com/property/4deb6074653ed0660507317b22a45c77ace8f51f65974e07603d4a253c9c5667.png",
        name: "saf2.png",
        reference: "",
      },
    },
    {
      id: "46",
      isAvailabele: false,
      name: "Intercom",
      image: {
        link: "https://nobrokerage.s3.ap-south-1.amazonaws.com/property/4139222cc156d74e581f5979a9188b932f9b5a624250802590dc93079e7aed6a.png",
        name: "saf3.png",
        reference: "",
      },
    },
    {
      id: "47",
      isAvailabele: false,
      name: "LPG",
      image: {
        link: "https://nobrokerage.s3.ap-south-1.amazonaws.com/property/e76506f90817d39462fb894631a0841dd694b721c41f8a16f7c9751ffa9fd32b.png",
        name: "saf4.png",
        reference: "",
      },
    },
    {
      id: "48",
      isAvailabele: false,
      name: "Security",
      image: {
        link: "https://nobrokerage.s3.ap-south-1.amazonaws.com/property/80f26169fa2fcd1e3167980c5fab753475e6df6fc5b0d88ba3d038ee8b0099bc.png",
        name: "saf5.png",
        reference: "",
      },
    },
  ],
  generalAmenities: [
    {
      id: "49",
      isAvailabele: false,
      name: "24 Hours Water Supply",
      image: {
        link: "https://nobrokerage.s3.ap-south-1.amazonaws.com/property/cce12fc3d64a0cbda590321c19fce3ed57bf773a01a8df17c7976267a876c6af.png",
        name: "g1.png",
        reference: "",
      },
    },
    {
      id: "50",
      isAvailabele: false,
      name: "Car Parking (Basement)",
      image: {
        link: "https://nobrokerage.s3.ap-south-1.amazonaws.com/property/d63ab243c8541305ddab1925b69d06d8eedaeb8fa61c32b1765200939a1615d3.png",
        name: "Rectangle g2.png",
        reference: "",
      },
    },
    {
      id: "51",
      isAvailabele: false,
      name: "Car  Parking (Under Shade)",
      image: {
        link: "https://nobrokerage.s3.ap-south-1.amazonaws.com/property/a0404d21a48b7c48fd1f21e22578a4c7748ff2a261e6c590e52601943fbee2cb.png",
        name: "g3.png",
        reference: "",
      },
    },
    {
      id: "52",
      isAvailabele: false,
      name: "Car Parking ( Open)",
      image: {
        link: "https://nobrokerage.s3.ap-south-1.amazonaws.com/property/064acb5dbaac80735864fbb25af1033320959a4e9b8b908b1e4da41e3cf61ea2.png",
        name: "g4.png",
        reference: "",
      },
    },
    {
      id: "53",
      isAvailabele: false,
      name: "Pet Area",
      image: {
        link: "https://nobrokerage.s3.ap-south-1.amazonaws.com/property/f4ec3e55df155a5ad1ff04a119953d3dfe0478501a44b96abadb2e8fdbb99930.png",
        name: "g5.png",
        reference: "",
      },
    },
    {
      id: "54",
      isAvailabele: false,
      name: "Garden",
      image: {
        link: "https://nobrokerage.s3.ap-south-1.amazonaws.com/property/1a1de7240e1818bdd19b40736b33a5edbf4a38802d475c8040802121c9feeb0f.png",
        name: "g6.png",
        reference: "",
      },
    },
    {
      id: "55",
      isAvailabele: false,
      name: "Lifts",
      image: {
        link: "https://nobrokerage.s3.ap-south-1.amazonaws.com/property/74f2339e0e7f3325f88d61d08c7fa566b0117dd87734d1b7830d21e814c545e3.png",
        name: "g7.png",
        reference: "",
      },
    },
    {
      id: "56",
      isAvailabele: false,
      name: "High Speed Lifts",
      image: {
        link: "https://nobrokerage.s3.ap-south-1.amazonaws.com/property/4639ae247396e75d5678a47270dba814315ca915fec19462b31adbff4ab90444.png",
        name: "g8.png",
        reference: "",
      },
    },
    {
      id: "57",
      isAvailabele: false,
      name: "Landscape Gardens",
      image: {
        link: "https://nobrokerage.s3.ap-south-1.amazonaws.com/property/26173ca24b25eb7f88ffceb2a7e22e0f2d06aee3303a3d6990288d0c452c3281.png",
        name: "g9.png",
        reference: "",
      },
    },
    {
      id: "58",
      isAvailabele: false,
      name: "Rainwater Harvesting",
      image: {
        link: "https://nobrokerage.s3.ap-south-1.amazonaws.com/property/eec6ac29ea0ce4a47f82cbd5c6d307dc42c899410f3a2f8edfa63e422b892e1c.png",
        name: "g10.png",
        reference: "",
      },
    },
    {
      id: "59",
      isAvailabele: false,
      name: "Power Backup (Full)",
      image: {
        link: "https://nobrokerage.s3.ap-south-1.amazonaws.com/property/0c8d5534c82706a5a77cfaaa59bee5a502c7ef9bbbad115bcb143af19be82327.png",
        name: "g11.png",
        reference: "",
      },
    },
    {
      id: "60",
      isAvailabele: false,
      name: "Power Backup Lift Only",
      image: {
        link: "https://nobrokerage.s3.ap-south-1.amazonaws.com/property/bb771b9f0e2a2059dae1d3b13e6cb1aa42b1d1e3ba7c7fad46bab1d74f0e6806.png",
        name: "g12.png",
        reference: "",
      },
    },
  ],
  otherAmenities: [
    {
      id: "61",
      isAvailabele: false,
      name: "BBQ Area",
      image: {
        link: "https://nobrokerage.s3.ap-south-1.amazonaws.com/property/d17ce800f1e2b08aff22da5b461f8169d71441928e7e7fd3d379e6e6e7dcc311.png",
        name: "o1.png",
        reference: "",
      },
    },
    {
      id: "62",
      isAvailabele: false,
      name: "Multi Utility Area",
      image: {
        link: "https://nobrokerage.s3.ap-south-1.amazonaws.com/property/5509ee03a099e83739b62d292f59a2b12526ed3bacbe6b406a3c324108b00215.png",
        name: "o2.png",
        reference: "",
      },
    },
    {
      id: "63",
      isAvailabele: false,
      name: "Bar",
      image: {
        link: "https://nobrokerage.s3.ap-south-1.amazonaws.com/property/7836806d841cfb55a3137737371aceac16c85afc9dec7bdd1e90deffb271b2cd.png",
        name: "o3.png",
        reference: "",
      },
    },
    {
      id: "64",
      isAvailabele: false,
      name: "Jacuzzi Spa",
      image: {
        link: "https://nobrokerage.s3.ap-south-1.amazonaws.com/property/b7ae41517d67cef6a3c68bb7e10413413552fa01b35206089dad0d97568280f3.png",
        name: "o4.png",
        reference: "",
      },
    },
    {
      id: "65",
      isAvailabele: false,
      name: "Valet Service",
      image: {
        link: "https://nobrokerage.s3.ap-south-1.amazonaws.com/property/6cc36c81c4727175cc5e13359437f33b410acdec98bc5b4d7d2f0a359b4c8900.png",
        name: "o5.png.png",
        reference: "",
      },
    },
    {
      id: "66",
      isAvailabele: false,
      name: "Central Garden Atrium",
      image: {
        link: "https://nobrokerage.s3.ap-south-1.amazonaws.com/property/274ba161c1efc50e5a3498d71e69ead3c3db788176cd4239f340581999067007.png",
        name: "o7.png",
        reference: "",
      },
    },
    {
      id: "67",
      isAvailabele: false,
      name: "Outdoor Party Area",
      image: {
        link: "https://nobrokerage.s3.ap-south-1.amazonaws.com/property/5498521dfb467c8c5e5546ccff22129fb40b4b20ac527c58e9244b2bfab5808a.png",
        name: "o8.png",
        reference: "",
      },
    },
    {
      id: "68",
      isAvailabele: false,
      name: "Laundry Service",
      image: {
        link: "https://nobrokerage.s3.ap-south-1.amazonaws.com/property/4782cd09d6a186557c1488a852e9e33f6a4084334ca669cba8571ba42165fecd.png",
        name: "o9.png",
        reference: "",
      },
    },
    {
      id: "69",
      isAvailabele: false,
      name: "Convinience Store",
      image: {
        link: "https://nobrokerage.s3.ap-south-1.amazonaws.com/property/7000c6c1039b737dbb03fc3ff34510eae466e804b99c5e9b992122bed9bfffe8.png",
        name: "o10.png",
        reference: "",
      },
    },
    {
      id: "70",
      isAvailabele: false,
      name: "Saloon",
      image: {
        link: "https://nobrokerage.s3.ap-south-1.amazonaws.com/property/2702e5906626d5ad8e44be05121b44fbbf47013f5e658686d2826c2a17855355.png",
        name: "o11.png",
        reference: "",
      },
    },
    {
      id: "71",
      isAvailabele: false,
      name: "Concierge",
      image: {
        link: "https://nobrokerage.s3.ap-south-1.amazonaws.com/property/8deba235baf6f36d061db1ddf039348adfe0412b06cc6def5f1107dcf38b443c.png",
        name: "o12.png",
        reference: "",
      },
    },
    {
      id: "72",
      isAvailabele: false,
      name: "Lounge Area",
      image: {
        link: "https://nobrokerage.s3.ap-south-1.amazonaws.com/property/d1f0656792d34319f6f8daf389034050677694634455352cd7d3274bca1bb7ca.png",
        name: "o13.png",
        reference: "",
      },
    },
    {
      id: "73",
      isAvailabele: false,
      name: "Fountain",
      image: {
        link: "https://nobrokerage.s3.ap-south-1.amazonaws.com/property/763019133fb0f2e9cf970c71d8bc27c14ab48d531ad33ec47580f16241b276df.png",
        name: "o14.png",
        reference: "",
      },
    },
    {
      id: "74",
      isAvailabele: false,
      name: "Sky Garden",
      image: {
        link: "https://nobrokerage.s3.ap-south-1.amazonaws.com/property/d4c6a4f0cc3d7a9b2ea33615f8534f5725792dcd8cc6d3b131363197df9dcabe.png",
        name: "o15.png",
        reference: "",
      },
    },
    {
      id: "75",
      isAvailabele: false,
      name: "Conference Room",
      image: {
        link: "https://nobrokerage.s3.ap-south-1.amazonaws.com/property/17186fc60eefbcd7a04f47129dc5fba9273adfa142fbeb9c0e69cda693d1ffa3.png",
        name: "o16.png",
        reference: "",
      },
    },
    {
      id: "76",
      isAvailabele: false,
      name: "Meeting Room",
      image: {
        link: "https://nobrokerage.s3.ap-south-1.amazonaws.com/property/7c7a72d44cdf2cb61e5ea2bc865dda125ff18e02d546f1e029ebd02828beda0b.png",
        name: "o17.png",
        reference: "",
      },
    },
    {
      id: "77",
      isAvailabele: false,
      name: "Medical Store",
      image: {
        link: "https://nobrokerage.s3.ap-south-1.amazonaws.com/property/f6a9e771dc5592b598369ca36c4fe4bad41a60c544b4484a5c94629e5651c263.png",
        name: "o18.png",
        reference: "",
      },
    },
    {
      id: "78",
      isAvailabele: false,
      name: "Sky Lounge",
      image: {
        link: "https://nobrokerage.s3.ap-south-1.amazonaws.com/property/460dbe4eb1edef2b680f60870b81b43fdf86d92dd9c585fe2f7b523591ba5db9.png",
        name: "o19.png",
        reference: "",
      },
    },
    {
      id: "79",
      isAvailabele: false,
      name: "Extended Sky Patios",
      image: {
        link: "https://nobrokerage.s3.ap-south-1.amazonaws.com/property/fc16f827785e17bd6f1ce06cf4455f29805814ba772b6fceb03242c7fcdf76dc.png",
        name: "o20.png",
        reference: "",
      },
    },
    {
      id: "80",
      isAvailabele: false,
      name: "Mini Theatre",
      image: {
        link: "https://nobrokerage.s3.ap-south-1.amazonaws.com/property/f1f100c2f6b6fdd0f2f83119aba1b5f9a6c7134cb8eff994d929601c37f4e801.png",
        name: "o21.png",
        reference: "",
      },
    },
    {
      id: "81",
      isAvailabele: false,
      name: "Mini Amphitheatre",
      image: {
        link: "https://nobrokerage.s3.ap-south-1.amazonaws.com/property/de8a9157f52edaf3268b8649dd19711e4a1d2989f05c76bbdc46d821d571167f.png",
        name: "o22.png",
        reference: "",
      },
    },
    {
      id: "82",
      isAvailabele: false,
      name: "Vegatable Shop",
      image: {
        link: "https://nobrokerage.s3.ap-south-1.amazonaws.com/property/15d8f0505c2841970f4ee680871c707cdcbf278854a58a4e0fda87a95c65da06.png",
        name: "o23.png",
        reference: "",
      },
    },
    {
      id: "83",
      isAvailabele: false,
      name: "Restaurent",
      image: {
        link: "https://nobrokerage.s3.ap-south-1.amazonaws.com/property/7a95d02768c392360b2e50a1321503f0a63ae5577d03e58ecccf8e4c5e459a2d.png",
        name: "o24.png",
        reference: "",
      },
    },
    {
      id: "84",
      isAvailabele: false,
      name: "Cafeteria",
      image: {
        link: "https://nobrokerage.s3.ap-south-1.amazonaws.com/property/3396d439c0b8db692bcd57a4c12be783fbce80c2cf045fd2731bd4ed6fcdf919.png",
        name: "o25.png",
        reference: "",
      },
    },
  ],
};
