import {
    ArrayInput, AutocompleteInput, BooleanInput, DateInput, Edit, NumberInput, ReferenceInput, SelectInput, SimpleFormIterator, TextInput, useEditController, TabbedForm, required, FormDataConsumer
} from "react-admin";
import { AreaUnitChoices, ConfigurationChoices, ExtraRoomChoices, FurnishingStateChoices, PowerBackupChoices, SellStatusTypeChoices, toWords } from "./propertyHelper";
import FileInput, { FileInputSingle } from "../../components/fileInput";
import { TenentsChoices } from "./create";
import { CustomAdd, CustomRemove, MapPicker } from "../OnGoingProjects/edit";
import { useWatch } from "react-hook-form";
import { EditAndDeleteToolbar, EditToolbar } from "../../components/toolbars/toolbars";
import { useSuperAdmin } from "../../context/superAdmin";
import { CustomEdit } from "../../components/customEdit";

export function PriceInWordsForMutation({ source = 'askPrice', ...props }) {
    const askPrice = useWatch({ name: source });
    if (!askPrice) return '';

    // console.log('askPrice', askPrice);

    return <div style={{
        // color: "#3284ff",
        fontWeight: "bold",
        marginBottom: "40px"
    }}>
        {
            toWords.convert(Number(askPrice))
        }
    </div>
}

export const PropertyEdit = (props) => {
    const { record } = useEditController();
    const superAdminContext = useSuperAdmin();
    return (
        <CustomEdit title={record?.name} {...props} mutationMode="pessimistic">
            <TabbedForm
                toolbar={superAdminContext.superAdmin ? <EditAndDeleteToolbar /> : <EditToolbar />}

            >
                <TabbedForm.Tab label="Basic Details">
                    <TextInput source="title" />
                    <TextInput source='description' multiline />
                    <ReferenceInput source="postedByUserId" reference="user">
                        <AutocompleteInput optionText="phone" defaultValue={null} filterToQuery={(q) => ({ phone: q })} />
                    </ReferenceInput>
                    {/* <SelectInput source="reviewStatus" choices={ReviewStausChoices} validate={required()} /> */}
                    <BooleanInput source="isForRent" />
                    <NumberInput sx={{ paddingBottom: "0", marginBottom: "0" }} source='askPrice' locales={'en-IN'} />
                    <PriceInWordsForMutation label="Price in Words" />
                    <NumberInput source='securityDeposite' />
                    <BooleanInput source='isMaintenenceIncluded' />
                    <NumberInput source='maintenance' />
                    <TextInput source='address' />
                    <DateInput source='postedOn' />
                    <TenentsChoices />
                    <SelectInput source="propertyConfiguration" choices={ConfigurationChoices} validate={required()} />
                    <AutocompleteInput source="extraRooms" choices={ExtraRoomChoices} multiple />
                    <SelectInput source="propertyConfiguration" choices={PowerBackupChoices} />
                    <SelectInput source="sellStatus" choices={SellStatusTypeChoices} validate={required()} />
                    <SelectInput source="furnishingState" choices={FurnishingStateChoices} validate={required()} />
                    <ReferenceInput source="cityId" reference="city">
                        <AutocompleteInput optionText="name" defaultValue={null} filterToQuery={(q) => ({ name: q })} />
                    </ReferenceInput>
                    <ReferenceInput source="localityId" reference="locality">
                        <AutocompleteInput optionText="name" defaultValue={null} filterToQuery={(q) => ({ name: q })} />
                    </ReferenceInput>
                    <ReferenceInput source="societyId" reference="society">
                        <AutocompleteInput optionText="name" defaultValue={null} filterToQuery={(q) => ({ name: q })} />
                    </ReferenceInput>
                </TabbedForm.Tab>

                <TabbedForm.Tab label="Property Details">
                    <NumberInput source='numberOfBedrooms' />
                    <NumberInput source='numberOfBathrooms' />
                    <NumberInput source='numberOfBalconies' />
                    <div style={{ display: 'flex', gap: "10px" }}>
                        <NumberInput source='superArea' />
                        <SelectInput source="superAreaUnit" choices={AreaUnitChoices} validate={required()} />
                    </div>
                    <div style={{ display: 'flex', gap: "10px" }}>
                        <NumberInput source='plotArea' />
                        <SelectInput source="plotAreaUnit" choices={AreaUnitChoices} validate={required()} />
                    </div>

                    <div style={{ display: 'flex', gap: "10px" }}>
                        <NumberInput source='carpetArea' />
                        <SelectInput source="carpetAreaUnit" choices={AreaUnitChoices} validate={required()} />
                    </div>
                    <FormDataConsumer>
                        {({ formData, ...rest }) => (
                            !formData.isForRent && <BooleanInput source='isUnderLoan' />
                        )}
                    </FormDataConsumer>

                    <NumberInput source='twoWheeler' />
                    <NumberInput source='fourWheeler' />
                    <BooleanInput source='isShared' />
                    <BooleanInput source='isCovered' />
                </TabbedForm.Tab>

                <TabbedForm.Tab label="NearBy">
                    <ArrayInput source="nearBy">
                        <SimpleFormIterator addButton={<CustomAdd text="Add Near By" />} removeButton={<CustomRemove text="Remove" />} disableClear inline disableReordering>
                            <TextInput source='text' />
                            <TextInput source='distance' />
                        </SimpleFormIterator>
                    </ArrayInput>
                </TabbedForm.Tab>

                <TabbedForm.Tab label={"Location"}>
                    <TextInput source='address' multiline />
                    <MapPicker />
                    {/* <NumberInput source="location.lat" label='Latitude' />
                    <NumberInput source="location.long" label='Longitude' />
                    <TextInput source="location.googleMapsUrl" label='Google Maps Url' fullWidth /> */}
                    <FileInputSingle source="locationMap" edit title="Loaction Map" />
                </TabbedForm.Tab>
                <TabbedForm.Tab label={"Media"}>
                    <FileInput source='media' edit add />
                </TabbedForm.Tab>

            </TabbedForm>
        </CustomEdit>
    )
}






