import {
    Show, SimpleShowLayout, TextField, useShowController
} from "react-admin";

export const ReportReasonShow = (props) => {
    const { record } = useShowController()
    return (
        <Show {...props} title={record?.name}>
            <SimpleShowLayout>
                
                <TextField source="title" />
                <TextField source="id" label="Id"/>
            </SimpleShowLayout>
        </Show>
    )
}


