import {
    Show, SimpleShowLayout, TextField, useShowController
} from "react-admin";

export const AppSettingsShow = (props) => {
    const { record } = useShowController()
    return (
        <Show {...props} title={record?.name}>
            <SimpleShowLayout>
                <TextField source="key" />
                <TextField source="type" />
                <TextField aria-multiline source="value" />
                <TextField aria-multiline source="description" />
            </SimpleShowLayout>
        </Show>
    )
}


