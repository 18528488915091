import {
    Create, NumberInput, SimpleForm, TextInput} from "react-admin";

export const StateCreate = (props) => (
    <Create {...props}>
        <SimpleForm>
            <TextInput source="name" />
            <TextInput source="id" label="State Id" />
            <NumberInput source="gstCode" label= "GST Code" />
        </SimpleForm>
    </Create>
);

