/* eslint-disable no-unused-vars */
import { required } from "ra-core";
import {
    AutocompleteInput, Edit, ReferenceInput, SelectInput, SimpleForm, TextInput, useEditController,
} from "react-admin";
import { EditAndDeleteToolbar, EditToolbar } from "../../components/toolbars/toolbars";
import { useSuperAdmin } from "../../context/superAdmin";
import { CustomEdit } from "../../components/customEdit";

const propertyStatusChoices = [
    {
        id: 'REGISTERED',
        name: 'Registered'
    },
    {
        id: 'SEEN',
        name: 'Seen'
    },
    {
        id: 'REJECTED',
        name: 'Rejected'
    },
    {
        id: 'ADDITIONAL_DETAILS_REQUIRED',
        name: 'Additional Details Required'
    },
    {
        id: 'ACTION_INITIATED',
        name: 'Action Initiated'
    },
    {
        id: 'ACTION_PENDING',
        name: 'Action Pending'
    },
    {
        id: 'RESOLVED',
        name: 'Resolved'
    },
]

export const ReportEdit = (props) => {
    const { record } = useEditController();
    const superAdminContext = useSuperAdmin()
    return (
        <CustomEdit title={record?.name} {...props} mutationMode="pessimistic">
            <SimpleForm

                toolbar={superAdminContext.superAdmin ? <EditAndDeleteToolbar /> : <EditToolbar />}

            >
                <ReferenceInput source="reasonId" reference="report-reason">
                    <AutocompleteInput optionText="name" defaultValue={props?.defaultValue} sx={{ width: "240px" }} />
                </ReferenceInput>
                <TextInput source='description' multiline />
                <SelectInput source="status" choices={propertyStatusChoices} defaultValue={props?.defaultValue} validate={required()} />
            </SimpleForm>
        </CustomEdit>
    )
}



