import {
     AutocompleteInput,  BooleanInput, Create,  ReferenceInput,  SimpleForm,  TextInput
} from "react-admin";

export const CityCreate = (props) => (
    <Create {...props}>
        <SimpleForm>
            <TextInput source="name" />
            <ReferenceInput source="stateId" reference="state">
                <AutocompleteInput optionText="name" defaultValue={null} />
            </ReferenceInput>
            <BooleanInput source="isPopularCity" />
        </SimpleForm>
    </Create>
);

