import {
    BooleanInput, Create, FormDataConsumer,  NumberInput,  SelectInput,  SimpleForm,  TextInput, required,  useInput
} from "react-admin";






export const AppSettingsCreate = (props) => {
    return (

        <Create {...props}>
            <SimpleForm validate={validateJson}>
                <TextInput source="key" />
                <SelectInput source="type" choices={[
                    {
                        id: "NUMBER",
                        name: "NUMBER"
                    },
                    {
                        id: "BOOLEAN",
                        name: "BOOLEAN"
                    },
                    {
                        id: "TEXT",
                        name: "TEXT"
                    },
                    {
                        id: "JSON",
                        name: "JSON"
                    }
                ]} validate={required()} />
                <FormDataConsumer>
                    {
                        (({ formData }) => {
                            if (formData?.type) {
                                switch (formData?.type) {
                                    case 'NUMBER': {
                                        return <NumberInput source='value' />;
                                    }
                                    case 'BOOLEAN': {
                                        return <BooleanInput source='value' />;
                                    }
                                    case 'TEXT': {
                                        return <TextInput source='value' />;
                                    }
                                    case 'JSON': {
                                        return <JsonInput source='value' label={"Value"} />;
                                    }
                                    default: {
                                        <></>
                                    }
                                }
                            }
                        })
                    }
                </FormDataConsumer>
                <TextInput source="description" multiline />
                {/* <ReactJson src={{}} onEdit={()=>{}} enableClipboard/> */}
                
            </SimpleForm>
        </Create>
    )
};

export const JsonInput = ({ source, label, ...rest }) => {
    const { id, field, fieldState } = useInput({ source });

    return (
        <div htmlFor={id} style={{display: "flex", flexDirection: "column"}}>
            {label}
            <textarea id={id} {...field}  {...rest} style={{minWidth: '500px', minHeight:'200px', backgroundColor: "black", color: "white", fontSize: "20px"  }} spellCheck={false}/>
            {fieldState.error && <span  style={{color: "red"}}>{fieldState.error.message}</span>}
        </div>
    );
};



export function validateJson(values) {
    let errors  = {};
    try {
        JSON.parse(values.value);
    } catch (e) {
        errors.value = 'Value must be JSON'
    }
    return errors;
}



