import {
    ArrayInput, AutocompleteInput, BooleanInput, Button, DateInput, Edit, FormDataConsumer, NumberInput, ReferenceInput, SelectInput, SimpleFormIterator, TabbedForm, TextInput, required, useEditController, useRecordContext,
} from "react-admin";
import { AreaUnitChoices, ConfigurationChoices, ExtraRoomChoices, FurnishingStateChoices, PowerBackupChoices, PropertyTypeChoices, defaultAmenities } from "../Property/propertyHelper";
import FileInput, { FileInputSingle } from "../../components/fileInput";
import { useCallback, useEffect, useRef, useState } from "react";
import { useFormContext } from 'react-hook-form';
import { CheckboxGroup, Modal } from "react-rainbow-components";
import { debounce } from "lodash";
import { GoogleMap, MarkerF, useJsApiLoader } from "@react-google-maps/api";
import Icon from './mappin.svg';
import { PriceInWordsForMutation } from "../Property/edit";
import { EditAndDeleteToolbar, EditToolbar } from "../../components/toolbars/toolbars";
import { useSuperAdmin } from "../../context/superAdmin";
import { CustomEdit } from "../../components/customEdit";


export function CustomAdd(props) {
    return (<Button style={{ border: "none", background: "#1976D2", color: 'white', fontWeight: "600", padding: "10px", borderRadius: "5px", fontSize: "16px" }}{...props} label={props?.text} />)
}

export function CustomRemove(props) {
    return (<Button style={{ border: "none", background: "red", color: 'white', fontWeight: "600", padding: "10px", borderRadius: "5px", fontSize: "16px" }} {...props} label={props?.text} />)
}


export const OngoingProjectEdit = (props) => {
    const { record } = useEditController();
    const superAdminContext = useSuperAdmin();
    return (
        <CustomEdit title={record?.name} {...props} mutationMode="pessimistic">
            <TabbedForm values={{ isOngoingProject: false }}
                toolbar={superAdminContext.superAdmin ? <EditAndDeleteToolbar /> : <EditToolbar />}

            >
                <TabbedForm.Tab label="Basic Details">
                    <TextInput source="title" />
                    <TextInput source='description' multiline fullWidth />
                    {/* <SelectInput source="reviewStatus" choices={ReviewStausChoices} validate={required()} /> */}
                    {/* <BooleanInput source="isForRent" /> */}
                    <NumberInput source='askPrice' />
                    <PriceInWordsForMutation label="Price in Words" />
                    {/* <NumberInput source='securityDeposite' /> */}
                    {/* <BooleanInput source='isMaintenenceIncluded' /> */}
                    {/* <NumberInput source='maintenance' /> */}
                    <DateInput source='postedOn' />
                    {/* <BooleanInput source='forFamily' />
                    <BooleanInput source='forBachelorBoys' />
                    <BooleanInput source='forBachelorGirls' /> */}
                    {/* <SelectInput source="ownershipType" choices={OwnerShipChoices} validate={required()} /> */}
                    {/* <SelectInput source="sellStatus" choices={SellStatusTypeChoices} validate={required()} /> */}
                    {/* <SelectInput source="propertyConfiguration" choices={ConfigurationChoices} validate={required()} /> */}
                    {/* <AutocompleteInput source="extraRooms" choices={ExtraRoomChoices} multiple /> */}
                    {/* <SelectInput source="furnishingState" choices={FurnishingStateChoices} validate={required()} /> */}
                    <SelectInput source="propertyType" choices={PropertyTypeChoices} validate={required()} />
                    <SelectInput source="powerBackup" choices={PowerBackupChoices} />

                    <ReferenceInput source="cityId" reference="city">
                        <AutocompleteInput optionText="name" defaultValue={null} filterToQuery={(q) => ({ name: q })} />
                    </ReferenceInput>
                    <ReferenceInput source="localityId" reference="locality">
                        <AutocompleteInput optionText="name" defaultValue={null} filterToQuery={(q) => ({ name: q })} />
                    </ReferenceInput>
                    <ReferenceInput source="societyId" reference="society">
                        <AutocompleteInput optionText="name" defaultValue={null} filterToQuery={(q) => ({ name: q })} />
                    </ReferenceInput>
                    <FileInputSingle source='brochure' title="Brochure" edit fileHeight={200} fileWidth={200} />
                </TabbedForm.Tab>

                <TabbedForm.Tab label="Parking Details">
                    {/* <NumberInput source='numberOfBedrooms' />
                    <NumberInput source='numberOfBathrooms' />
                    <NumberInput source='numberOfBalconies' />
                    <NumberInput source='masterArea' />
                    <NumberInput source='plotArea' />
                    <NumberInput source='carpetArea' />
                    <BooleanInput source='isUnderLoan' /> */}
                    <NumberInput source='twoWheeler' />
                    <NumberInput source='fourWheeler' />
                    {/* <BooleanInput source='isShared' />
                    <BooleanInput source='isCovered' /> */}
                </TabbedForm.Tab>

                <TabbedForm.Tab label="Near By">
                    <ArrayInput source="nearBy">
                        <SimpleFormIterator addButton={<CustomAdd text="Add Near By" />} removeButton={<CustomRemove text="Remove" />} disableClear inline disableReordering>
                            <TextInput source='text' />
                            <TextInput source='distance' />

                        </SimpleFormIterator>
                    </ArrayInput>


                </TabbedForm.Tab>
                <TabbedForm.Tab label="Floor Plans">
                    <FileInputSingle source='sitePlan' edit title="Upload site Plan" fileHeight={200} fileWidth={200} />
                    <div style={{ display: 'flex', width: '80vw', height: '2px', background: "red" }}></div>
                    <ArrayInput source="sizeAndFloorPlan" label="Floor Plans">
                        <SimpleFormIterator addButton={<CustomAdd text="Add Floor Plan" />} removeButton={<CustomRemove text="Remove" />} disableClear inline disableReordering>
                            <SelectInput source="planConfig" choices={ConfigurationChoices} validate={required()} />
                            <NumberInput source='planArea' />
                            <SelectInput source="planAreaUnit" choices={AreaUnitChoices} />
                            <FileInputSingle source='planImage' edit fileHeight={200} fileWidth={200} title="Image" />
                        </SimpleFormIterator>
                    </ArrayInput>

                </TabbedForm.Tab>

                <TabbedForm.Tab label="Payment Plans">
                    <ArrayInput source="projectLayingPayments"  >
                        <SimpleFormIterator inline sx={{ borderColor: "blue" }} addButton={<CustomAdd text="Add payment Plan" />} removeButton={<CustomRemove text="Remove" />} disableClear disableReordering >
                            <TextInput source="paymentHeader" />
                            {/* <NumberInput source='securityAmount' /> */}
                            <FormDataConsumer>
                                {
                                    ({ getSource }) => (
                                        record?.projectLayingPayments && <ArrayInput source={getSource?.('payments')}>
                                            <SimpleFormIterator inline addButton={<CustomAdd text="Add+" />} removeButton={<CustomRemove text="Remove" />} disableClear disableReordering>
                                                <TextInput source="paymentName" />
                                                <BooleanInput source="isPercentage" />
                                                <NumberInput source="value" />
                                                {/* <PriceInWordsForMutation source="value" label="Amount in words"/> */}
                                            </SimpleFormIterator>
                                        </ArrayInput>
                                    )
                                }
                            </FormDataConsumer>
                            <div style={{ display: 'flex', width: '80vw', height: '2px', background: "red" }}></div>
                        </SimpleFormIterator>
                    </ArrayInput>
                    {/* <FileInput source='sitePlan' edit /> */}
                </TabbedForm.Tab>

                {/* <TabbedForm.Tab label="Specifications">
                    <ArrayInput source="projectSpecifications"  >
                        <SimpleFormIterator inline addButton={<CustomAdd text="Add Specification" />} removeButton={<CustomRemove text="Remove" />} disableClear disableReordering>
                            <TextInput source="type" />
                            
                            <FormDataConsumer>
                                {
                                    ({ getSource }) => (

                                        record?.projectSpecifications && <ArrayInput source={getSource?.('sizes')}>
                                            <SimpleFormIterator inline addButton={<CustomAdd text="Add Size" />} removeButton={<CustomRemove text="Remove" />} disableClear disableReordering>
                                                <NumberInput source="size" />
                                                <SelectInput source="sizeUnit" choices={AreaUnitChoices} />
                                            </SimpleFormIterator>
                                        </ArrayInput>
                                    )
                                }
                            </FormDataConsumer>
                            <div style={{ display: 'flex', width: '80vw', height: '2px', background: "red" }}></div>
                        </SimpleFormIterator>
                    </ArrayInput>

                </TabbedForm.Tab> */}

                <TabbedForm.Tab label="FAQ">
                    <ArrayInput source="faq"  >
                        <SimpleFormIterator inline fullWidth addButton={<CustomAdd text="Add An FAQ" />} removeButton={<CustomRemove text="Remove" />} disableClear disableReordering >
                            <TextInput source="question" fullWidth />
                            <TextInput source="answer" multiline sx={{ width: '750px' }} />
                        </SimpleFormIterator>
                    </ArrayInput>

                </TabbedForm.Tab>

                <TabbedForm.Tab label="Amenities">

                    {/* <ArrayInput source="amenities.club" label="Club">
                        <SimpleFormIterator inline fullWidth addButton={<CustomAdd  text="Add in Club"/>} removeButton={<CustomRemove text="Remove" />}   disableClear  disableReordering  disableAdd disableRemove>
                            <BooleanInput source="isAvailabele" />
                            <TextInput source="name" />
                            <FileInputSingle source="image" fileHeight={100} fileWidth={100} title=""/>
                        </SimpleFormIterator>
                    </ArrayInput>
                    <div style={{ display: 'flex', width: '80vw', height: '2px', background: "red" }}></div>
                    <ArrayInput source="amenities.sportsAndFitness" label="Sports and Fitness" >
                        <SimpleFormIterator inline fullWidth addButton={<CustomAdd  text="Add Sports And Fitness"/>} removeButton={<CustomRemove text="Remove" />}   disableClear  disableReordering  disableAdd disableRemove>
                            <BooleanInput source="isAvailabele" />
                            <TextInput source="name" />
                            <FileInputSingle source="image" fileHeight={100} fileWidth={100} title="" />
                        </SimpleFormIterator>
                    </ArrayInput>
                    <div style={{ display: 'flex', width: '80vw', height: '2px', background: "red" }}></div>
                    <ArrayInput source="amenities.forChildren" label="For Children" >
                        <SimpleFormIterator inline fullWidth  addButton={<CustomAdd  text="Add In For Children"/>} removeButton={<CustomRemove text="Remove" />}   disableClear   disableReordering  disableAdd disableRemove>
                            <BooleanInput source="isAvailabele" />
                            <TextInput source="name" />
                            <FileInputSingle source="image" fileHeight={100} fileWidth={100} title=""/>
                        </SimpleFormIterator>
                    </ArrayInput>
                    <div style={{ display: 'flex', width: '80vw', height: '2px', background: "red" }}></div>
                    <ArrayInput source="amenities.safetyAndSecurity" label="Safety and Security"  >
                        <SimpleFormIterator inline fullWidth  addButton={<CustomAdd  text="Add in Safety and Security"/>} removeButton={<CustomRemove text="Remove" />}   disableClear   disableReordering disableAdd disableRemove>
                            <BooleanInput source="isAvailabele" />
                            <TextInput source="name" />
                            <FileInputSingle source="image" fileHeight={100} fileWidth={100} title="" />
                        </SimpleFormIterator>
                    </ArrayInput>
                    <div style={{ display: 'flex', width: '80vw', height: '2px', background: "red" }}></div>
                    <ArrayInput source="amenities.generalAmenities" label="General Amenities" >
                        <SimpleFormIterator inline fullWidth addButton={<CustomAdd  text="Add in General Amenities "/>} removeButton={<CustomRemove text="Remove" />}   disableClear   disableReordering  disableAdd disableRemove>
                            <BooleanInput source="isAvailabele" />
                            <TextInput source="name" />
                            <FileInputSingle source="image" fileHeight={100} fileWidth={100} title="" />
                        </SimpleFormIterator>
                    </ArrayInput>
                    <div style={{ display: 'flex', width: '80vw', height: '2px', background: "red" }}></div>
                    <ArrayInput source="amenities.otherAmenities" label="Other  Amenities" >
                        <SimpleFormIterator inline fullWidth addButton={<CustomAdd  text="Add  in Others "/>} removeButton={<CustomRemove text="Remove" />}   disableClear   disableReordering  disableAdd disableRemove>
                            <BooleanInput source="isAvailabele" />
                            <TextInput source="name" />
                            <FileInputSingle source="image" fileHeight={100} fileWidth={100}  title=""/>
                        </SimpleFormIterator>
                    </ArrayInput> */}
                    <AmenitiesEdit />
                </TabbedForm.Tab>

                <TabbedForm.Tab label="Contact Info">
                    <TextInput source="contactInfoForProjects.name" label='Name' />
                    <TextInput source="contactInfoForProjects.phone" label="Phone" />
                    <TextInput source="contactInfoForProjects.email" label="Email" />
                </TabbedForm.Tab>
                <TabbedForm.Tab label={"Location"}>
                    <TextInput source='address' multiline />
                    {/* <NumberInput source="location.lat" label='Latitude' />
                    <NumberInput source="location.long" label='Longitude' /> */}
                    <MapPicker />

                    {/* <TextInput source="location.googleMapsUrl" label='Google Maps Url' fullWidth /> */}
                    <FileInputSingle source="locationMap" edit title="Loaction Map" />
                </TabbedForm.Tab>
                <TabbedForm.Tab label={"Media"}>
                    <FileInput source='media' edit add />
                </TabbedForm.Tab>
            </TabbedForm>
        </CustomEdit>
    )
}


export function MapPicker({ className }) {
    const mapRef = useRef(null);
    const record = useRecordContext();
    const [coords, setCoords] = useState({
        lat: 12.8935413,
        lng: 77.6405563,
    });
    const formContext = useFormContext();
    console.log("formContext", formContext.getValues());

    const { isLoaded } = useJsApiLoader({
        id: "google-map-script",
        googleMapsApiKey: process.env.REACT_APP_MAPS_API_KEY,
    });

    const [map, setMap] = useState(null);

    const [open, setOpen] = useState(false);

    const onLoad = useCallback(function callback(map) {
        // This is just an example of getting and using the map instance!!! don't just blindly copy!
        const bounds = new window.google.maps.LatLngBounds(coords);
        map?.fitBounds(bounds);
        console.log(
            "map _____________________________________________________________________________",
            map
        );

        // var Latlng_0 = new google.maps.LatLng(12.8935413, 77.6405563);
        // var marker_0 = new google.maps.Marker({
        //   position: Latlng_0,
        //   title: "0",
        //   draggable: true,
        // });

        // marker_0.setMap(map);

        setMap(map);
    }, []);

    const onUnmount = useCallback(function callback(map) {
        setMap(null);
    }, []);

    useEffect(() => {
        if (Number(record?.location?.lat) && Number(record?.location?.long)) {
            setCoords({
                lat: Number(record?.location?.lat),
                lng: Number(record?.location?.long),
            });
        } else {
            setCoords({
                lat: 12.8935413,
                lng: 77.6405563,
            });
        }
    }, []);

    useEffect(() => {
        formContext.setValue("location", {
            lat: coords.lat,
            long: coords.lng,
        }, { shouldDirty: true });
    }, [coords]);

    console.log("coords......", coords);

    const onCenterChangeHandler = useCallback(
        debounce(function () {
            console.log("called");
            const map = mapRef.current?.state?.map;
            setCoords({
                lat: Number(map?.center?.lat()),
                lng: Number(map?.center?.lng()),
            });
        }, 1000),
        [mapRef.current]
    );

    return (

        <div className='bd'>



            <div className="map__div">
                <div
                    onClick={() => {
                        setOpen(true);
                    }}
                    className={"select__map"}
                >
                    Select Location From Map
                </div>

                <div className={"lat__lng"}>
                    <div>{`Latitude: ${coords.lat}`}</div>
                    <div>{`Longitude: ${coords.lng}`}</div>
                </div>
            </div>

            <Modal
                isOpen={open}
                onRequestClose={() => {
                    setOpen(false);
                }}
                className={"mapmodal"}
                size='large'

            >
                <p>Center the location  to  be selected. </p>
                <div className={"modal__div"}>
                    {isLoaded && (
                        <GoogleMap
                            mapContainerClassName={"mapcontainer"}
                            center={{ lat: coords.lat, lng: coords.lng }}
                            onLoad={onLoad}
                            onUnmount={onUnmount}
                            ref={mapRef}
                            onDragEnd={onCenterChangeHandler}
                            tilt={0}


                            options={{
                                streetViewControl: false,
                                isFractionalZoomEnabled: true,
                                mapTypeControl: false,

                            }}
                        >
                            {/* <MarkerF
                                position={{ lat: coords.lat, lng: coords.lng }}
                                // icon={
                                //     "http://maps.google.com/mapfiles/ms/icons/green-dot.png"
                                // }
                                draggable={true}
                                onDragEnd={(e) => {
                                    console.log('e', e.latLng.lat());
                                    setCoords({
                                        lat: e.latLng.lat(),
                                        lng: e.latLng.lng()
                                    })
                                }}

                            /> */}
                        </GoogleMap>
                    )}
                    <div className={"marker"}>
                        <img src={Icon} height={40} width={40} alt={"marker"} />
                    </div>
                </div>
            </Modal>
        </div>

    );

}

export function AmenitiesEdit(props) {
    const [club, setClub] = useState([]);
    const [sports, setSports] = useState([]);
    const [children, setChildren] = useState([]);
    const [safety, setSafety] = useState([]);
    const [general, setGeneral] = useState([]);
    const [other, setOther] = useState([]);
    // const [amenities, setAmenities] = useState(defaultAmenities);
    const formCtx = useFormContext()
    function clubChange(values) {
        setClub(values)
    };
    function sportsChange(values) {
        setSports(values)
    };
    function childrenChange(values) {
        setChildren(values)
    };
    function safetyChange(values) {
        setSafety(values)
    };
    function generalChange(values) {
        setGeneral(values)
    };

    function otherChange(values) {
        setOther(values)
    };

    const record = useRecordContext();

    // console.log('record',  record);

    useEffect(() => {
        if (record?.amenities) {
            const amenities = { ...record?.amenities };
            setClub(amenities.club.filter(it => it?.isAvailabele).map(it => it?.id));
            setChildren(amenities.forChildren.filter(it => it?.isAvailabele).map(it => it?.id));
            setSports(amenities.sportsAndFitness.filter(it => it?.isAvailabele).map(it => it?.id));
            setSafety(amenities.safetyAndSecurity.filter(it => it?.isAvailabele).map(it => it?.id));
            setGeneral(amenities.generalAmenities.filter(it => it?.isAvailabele).map(it => it?.id));
            setOther(amenities.otherAmenities.filter(it => it?.isAvailabele).map(it => it?.id));
        }
    }, [])



    useEffect(() => {
        defaultAmenities.club.forEach((it, index) => {
            if (club.includes(it?.id)) {
                defaultAmenities.club[index].isAvailabele = true
            } else {
                defaultAmenities.club[index].isAvailabele = false
            }
        })
        defaultAmenities.sportsAndFitness.forEach((it, index) => {
            if (sports.includes(it?.id)) {
                defaultAmenities.sportsAndFitness[index].isAvailabele = true
            } else {
                defaultAmenities.sportsAndFitness[index].isAvailabele = false
            }
        })
        defaultAmenities.forChildren.forEach((it, index) => {
            if (children.includes(it?.id)) {
                defaultAmenities.forChildren[index].isAvailabele = true
            } else {
                defaultAmenities.forChildren[index].isAvailabele = false
            }
        })
        defaultAmenities.safetyAndSecurity.forEach((it, index) => {
            if (safety.includes(it?.id)) {
                defaultAmenities.safetyAndSecurity[index].isAvailabele = true
            } else {
                defaultAmenities.safetyAndSecurity[index].isAvailabele = false
            }
        })
        defaultAmenities.generalAmenities.forEach((it, index) => {
            if (general.includes(it?.id)) {
                defaultAmenities.generalAmenities[index].isAvailabele = true
            } else {
                defaultAmenities.generalAmenities[index].isAvailabele = false
            }
        })
        defaultAmenities.otherAmenities.forEach((it, index) => {
            if (other.includes(it?.id)) {
                defaultAmenities.otherAmenities[index].isAvailabele = true
            } else {
                defaultAmenities.otherAmenities[index].isAvailabele = false
            }
        });
        // setAmenities(defaultAmenities);
        formCtx.setValue('amenities', defaultAmenities, { shouldDirty: true });
    }, [club, sports, safety, children, general, other]);

    // console.log('formCtx', formCtx);
    // console.log("amenities", { club, sports, safety, children, other, general });

    return (
        <div className="rainbow-p-vertical_large rainbow-p-left_xx-large amenities" >
            <CheckboxGroup
                id="checkbox-group-1"
                label="Club Amenities"
                options={defaultAmenities?.club.map(it => ({ value: it?.id, label: it?.name, disabled: false }))}
                value={club}
                onChange={clubChange}
                className="amenity"
                {...props}
            />
            <CheckboxGroup
                id="checkbox-group-1"
                label="Sports Amenities"
                options={defaultAmenities?.sportsAndFitness.map(it => ({ value: it?.id, label: it?.name, disabled: false }))}
                value={sports}
                onChange={sportsChange}
                className="amenity"
                {...props}
            />
            <CheckboxGroup
                id="checkbox-group-1"
                label="For Children"
                options={defaultAmenities?.forChildren.map(it => ({ value: it?.id, label: it?.name, disabled: false }))}
                value={children}
                onChange={childrenChange}
                className="amenity"
                {...props}
            />
            <CheckboxGroup
                id="checkbox-group-1"
                label="Safety &  Security"
                options={defaultAmenities?.safetyAndSecurity.map(it => ({ value: it?.id, label: it?.name, disabled: false }))}
                value={safety}
                onChange={safetyChange}
                className="amenity"
                {...props}
            />
            <CheckboxGroup
                id="checkbox-group-1"
                label="General Amenities"
                options={defaultAmenities?.generalAmenities.map(it => ({ value: it?.id, label: it?.name, disabled: false }))}
                value={general}
                onChange={generalChange}
                className="amenity"
                {...props}
            />
            <CheckboxGroup
                id="checkbox-group-1"
                label="Other Amenities"
                options={defaultAmenities?.otherAmenities.map(it => ({ value: it?.id, label: it?.name, disabled: false }))}
                value={other}
                onChange={otherChange}
                className="amenity"
                {...props}
            />
        </div>
    );

}






